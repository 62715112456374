import { OssPidRequest } from '../model/oss-pid-request';
import { RequestData } from '../model/request-data';
import { REQUEST_TYPE, VERSION } from '../core/constants/oss-insight-constants';
import { LoginInfoBean } from '../modules/dashboard/model/login-info-bean';
import * as i0 from "@angular/core";
import * as i1 from "./util.service";
import * as i2 from "../core/shared/service/local-storage-service";
export class OssHelperService {
    constructor(utilService, localStorageService) {
        this.utilService = utilService;
        this.localStorageService = localStorageService;
    }
    buildOssPidRequest() {
        const ossPidRequest = new OssPidRequest();
        ossPidRequest.requestData = new RequestData();
        ossPidRequest.requestData.oauth_data = this.localStorageService.getOauthData();
        ossPidRequest.requestData.oauth_token = this.localStorageService.getOauthToken();
        ossPidRequest.requestData.ipAddress = this.utilService.getIpAddress();
        ossPidRequest.requestData.userAgent = this.utilService.getBrowserUserAgent();
        const dashboardId = this.localStorageService.getCurrentDashboard().id;
        ossPidRequest.requestData.dashboardId = dashboardId ? dashboardId : '1';
        ossPidRequest.requestData.id = dashboardId ? dashboardId : '1';
        ossPidRequest.requestType = REQUEST_TYPE;
        ossPidRequest.version = VERSION;
        return ossPidRequest;
    }
    buildOssPidRequestForCallBack() {
        const ossPidRequest = new OssPidRequest();
        ossPidRequest.requestData = new RequestData();
        ossPidRequest.requestData.oauth_data = this.localStorageService.getOauthData();
        ossPidRequest.requestData.oauth_token = this.localStorageService.getOauthToken();
        ossPidRequest.requestData.ipAddress = this.utilService.getIpAddress();
        ossPidRequest.requestData.userAgent = this.utilService.getBrowserUserAgent();
        ossPidRequest.requestType = REQUEST_TYPE;
        ossPidRequest.version = VERSION;
        return ossPidRequest;
    }
    buildOssPidRequestLoginForCallBack() {
        const ossPidRequest = new LoginInfoBean();
        ossPidRequest.oauth_data = this.localStorageService.getOauthData();
        ossPidRequest.oauth_token = this.localStorageService.getOauthToken();
        return ossPidRequest;
    }
    buildOssPidRequestLogout() {
        const ossPidRequest = new LoginInfoBean();
        ossPidRequest.oauth_data = this.localStorageService.getOauthData();
        ossPidRequest.oauth_token = this.localStorageService.getOauthToken();
        return ossPidRequest;
    }
    buildRequestBodyForComponentWiseData(rb) {
        const reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    id: rb.id,
                    filter_key: this.localStorageService.getDashboardFilterKey(),
                }
            }
        };
        return reqBody;
    }
    buildRequestBodyForChartData(rb) {
        const data = { dbObjId: rb.dbObjId,
            filterKey: this.localStorageService.getDashboardFilterKey(),
        };
        return data;
    }
    buildRequestBodyForChartDataInside(dbObjId) {
        const data = { dbObjId: dbObjId,
            filterKey: this.localStorageService.getDashboardFilterKey() };
        return data;
    }
    buildRequestBodyForChartInsideData(rb) {
        const data = { dbObjId: rb.child_obj_id,
            filterKey: this.localStorageService.getDashboardFilterKey(),
            __param: rb.__param,
            __param2: rb.__param2,
            parentTitle: rb.responseData.title,
            parentValue: rb.responseData.value
        };
        return data;
    }
    buildOssPidRequestForChronologyData() {
        const ossPidRequest = this.buildOssPidRequest();
        // let paramData: string[] = codeStatus.paramData.split(',');
        ossPidRequest.requestData.position = 5001;
        ossPidRequest.requestData.dashboardId = this.localStorageService.getCurrentDashboard().id;
        ossPidRequest.requestData.filter_key = this.localStorageService.getDashboardFilterKey();
        return ossPidRequest;
    }
    butildRequstDataForInsideDetail(parentChartData) {
        const reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent(),
                    token: '',
                    position: '503',
                    filter_key: this.localStorageService.getDashboardFilterKey(),
                    parent_obj_id: parentChartData.parent_obj_id,
                    child_obj_id: parentChartData.child_obj_id,
                    __param: parentChartData.__param,
                    __param2: parentChartData.__param2
                }
            }
        };
        return reqBody;
    }
    buildCommonRequestBody() {
        // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
        const reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent()
                }
            }
        };
        return reqBody;
    }
    buildRequestBodyForAssignDashboardByUserId(userId, dashboardId) {
        // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
        const reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent(),
                    user_id: userId,
                    dashboardId
                }
            }
        };
        return reqBody;
    }
    buildRequestBodyForChangeAdminStatusByUserId(userId, admin) {
        // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
        const reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent(),
                    user_id: userId,
                    admin
                }
            }
        };
        return reqBody;
    }
    buildRequestBodyForChangeUserStatusByUserId(userId, userStatus) {
        // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
        const reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent(),
                    user_id: userId,
                    user_status: userStatus
                }
            }
        };
        return reqBody;
    }
    buildRequestBodyForEnableDisableDeviceByUserId(userId) {
        // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
        const reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent(),
                    user_id: userId
                }
            }
        };
        return reqBody;
    }
    buildRequestBodyForAssignDashboardObject(dashboardObjId, dashboardId) {
        // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
        const reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent(),
                    dashboard_object_id: dashboardObjId,
                    dashboardId
                }
            }
        };
        return reqBody;
    }
    buildRequestBodyForAssignUserType(userTypeId, dashboardId) {
        // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
        const reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent(),
                    user_type: userTypeId,
                    dashboardId
                }
            }
        };
        return reqBody;
    }
    buildRequestForDeviceDisplay(tvDashboard) {
        const reqBody = {
            osspidRequest: {
                requestType: 'APP_DASHBOARD',
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent(),
                    token: '',
                    id: tvDashboard.id,
                    code1: tvDashboard.code1,
                    code2: tvDashboard.code2,
                }
            }
        };
        return reqBody;
    }
    buildRequestBodyForCreatingDashboard(dashboardTableData) {
        // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
        const reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent(),
                    dashboard: {
                        caption: dashboardTableData.caption,
                        desktop: dashboardTableData.desktop,
                        mobile: dashboardTableData.mobile,
                        objId: dashboardTableData.objId,
                        status: dashboardTableData.status,
                        timeline: dashboardTableData.timeline,
                        totalDurationTv: dashboardTableData.totalDurationTv,
                        tv: dashboardTableData.tv
                    }
                }
            }
        };
        return reqBody;
    }
    buildRequestBodyForCreatingUserType(userTypeListData) {
        // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
        const reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent(),
                    user_type_obj: {
                        id: userTypeListData.id,
                        typeName: userTypeListData.typeName,
                        status: userTypeListData.status
                    },
                }
            }
        };
        return reqBody;
    }
    buildRequestBodyForUpdatingDashboard(dashboardTableData) {
        // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
        const reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent(),
                    dashboard: {
                        id: dashboardTableData.id,
                        caption: dashboardTableData.caption,
                        desktop: dashboardTableData.desktop,
                        mobile: dashboardTableData.mobile,
                        objId: dashboardTableData.objId,
                        status: dashboardTableData.status,
                        timeline: dashboardTableData.timeline,
                        totalDurationTv: dashboardTableData.totalDurationTv,
                        tv: dashboardTableData.tv
                    }
                }
            }
        };
        return reqBody;
    }
    buildRequestBodyForUpdatingUserType(userTypeListData) {
        // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
        const reqBody = {
            osspidRequest: {
                requestType: REQUEST_TYPE,
                deviceId: this.utilService.getDeviceId(),
                clientId: this.utilService.getClientId(),
                secretKey: this.utilService.getSecretKey(),
                version: VERSION,
                requestData: {
                    oauth_token: this.localStorageService.getOauthToken(),
                    oauth_data: this.localStorageService.getOauthData(),
                    ipAddress: this.utilService.getIpAddress(),
                    userAgent: this.utilService.getBrowserUserAgent(),
                    user_type_obj: {
                        id: userTypeListData.id,
                        typeName: userTypeListData.typeName,
                        status: userTypeListData.status
                    },
                }
            }
        };
        return reqBody;
    }
}
OssHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OssHelperService_Factory() { return new OssHelperService(i0.ɵɵinject(i1.UtilService), i0.ɵɵinject(i2.LocalStorageService)); }, token: OssHelperService, providedIn: "root" });
