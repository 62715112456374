import { Component, OnInit, Inject } from "@angular/core";
import { MatDialog } from "@angular/material";
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA
} from "@angular/material/snack-bar";
import { UtilService } from "src/app/utils/util.service";
import { DownloadDialogComponent } from "../../admin-module/dialogBox/download-dialog/download-dialog.component";
import { DownloadiosDialogComponent } from "../../admin-module/dialogBox/downloadios-dialog/downloadios-dialog.component";

@Component({
  selector: "app-basic-snackbar",
  templateUrl: "./suggestion-snackbar.component.html",
  styleUrls: ["./suggestion-snackbar.component.css"]
})
export class SuggestionSnackbarComponent implements OnInit {
  constructor(
    public sbRef: MatSnackBarRef<SuggestionSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private dialog: MatDialog,
    public util: UtilService,//used in html
  ) {}
  ngOnInit() {}
}