import {Injectable} from "@angular/core";
import {CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGurd implements CanActivate {
  constructor(private router: Router,
              private authService: AuthService) {
  }

  canActivate(): Observable<boolean> | boolean {
    if (this.authService.isLoginIn() != undefined) {
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }
}
