import { Observable } from 'rxjs';
import { ApiEndpoints } from './../api-endpoints';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class OssService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.apiEndpoints = new ApiEndpoints();
    }
    ossVerification(ossPidRequest) {
        return this.httpClient.post(this.apiEndpoints.ossVerificationUrl, ossPidRequest);
    }
    ossLogout(ossPidRequest) {
        return this.httpClient.post(this.apiEndpoints.ossLogOutUrl, ossPidRequest);
    }
    userStatusCheck(ossPidRequest) {
        return this.httpClient.post(this.apiEndpoints.statusCheckUrl, ossPidRequest);
    }
}
OssService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OssService_Factory() { return new OssService(i0.ɵɵinject(i1.HttpClient)); }, token: OssService, providedIn: "root" });
