import * as i0 from "@angular/core";
import * as i1 from "../../login/storage.service";
export class LocalStorageService {
    constructor(storageService) {
        this.storageService = storageService;
    }
    getRegisteredUrlData() {
        return this.storageService.read('registered_url');
    }
    // getCodeStatusData(): CodeStatus[] {
    //   return this.storageService.read('code_status');
    // }
    getOauthData() {
        return this.storageService.read('oauth_data');
    }
    getOauthToken() {
        return this.storageService.read('oauth_token');
    }
    getJWTToken() {
        return this.storageService.read('jwt_token');
    }
    getExpireInJWTToken() {
        return this.storageService.read('jwt_expireIn');
    }
    getDashboardObjs() {
        return this.storageService.read('dashboard_obj');
    }
    getDashboardFilterKey() {
        return this.storageService.read('dashboardFilter') === null ? 'this_month' : this.storageService.read('dashboardFilter');
    }
    getUserId() {
        return this.storageService.read('userId');
    }
    saveUserCredentials(oauthData, oauthToken) {
        this.storageService.save('oauth_data', oauthData);
        this.storageService.save('oauth_token', oauthToken);
    }
    saveUserId(userId) {
        this.storageService.save('userId', userId);
    }
    setJWTToken(jwttoken) {
        return this.storageService.save('jwt_token', jwttoken);
    }
    setExpireInJWTToken(expireIn) {
        return this.storageService.save('jwt_expireIn', expireIn);
    }
    saveCallbackUrl(url) {
        this.storageService.save('callback_url', url);
    }
    // saveCodeStatus(codeStatusList: CodeStatus[]) {
    //   this.storageService.save('code_status', codeStatusList);
    // }
    saveRegisteredUrl(registeredUrlList) {
        this.storageService.save('registered_url', registeredUrlList);
    }
    saveDashboardObjs(dashboardObjList) {
        this.storageService.save('dashboard_obj', dashboardObjList);
    }
    saveDashboardMenus(dashboardMenuList) {
        this.storageService.save('dashboard_menu', dashboardMenuList);
    }
    getDashboardMenus() {
        return this.storageService.read('dashboard_menu');
    }
    saveDashboardFilterKey(st) {
        // console.log('Timeline Filter === >>>', st);
        this.storageService.save('dashboardFilter', st);
    }
    saveCurrentDashboard(obj) {
        this.storageService.save('currentDashboardObj', obj);
    }
    getCurrentDashboard() {
        return this.storageService.read('currentDashboardObj');
    }
    saveAdmin(obj) {
        this.storageService.save('admin', obj);
    }
    getAdmin() {
        return this.storageService.read('admin');
    }
    saveIsTv(obj) {
        this.storageService.save('isTv', obj);
    }
    getIsTv() {
        return this.storageService.read('isTv');
    }
    saveReportFormData(obj) {
        this.storageService.save('reportFormData', obj);
    }
    getReportFormData() {
        return this.storageService.read('reportFormData');
    }
    saveReportExplorerData(tableData, displayedColumns, formFields, obj, reportTitle) {
        const reportExploreData = {
            tableData: tableData,
            displayedColumns: displayedColumns,
            formFields: formFields,
            params: obj.params,
            reportTitle: reportTitle
        };
        this.storageService.save('reportExploreData', reportExploreData);
    }
    getReportExploreData() {
        return this.storageService.read('reportExploreData');
    }
    saveUserInfoForEmailLogin(info) {
        this.storageService.save('userInfoByEmail', info);
    }
    getUserInfoForEmailLogin() {
        return this.storageService.read('userInfoByEmail');
    }
}
LocalStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalStorageService_Factory() { return new LocalStorageService(i0.ɵɵinject(i1.StorageService)); }, token: LocalStorageService, providedIn: "root" });
