import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './core/login/login.component';
import { DefaultLayoutComponent } from './core/default-layout/default-layout.component';
import { AuthGurd } from './core/login/auth.gurd';
import { OssInsightComponent } from './core/oss-insight/oss-insight.component';
import { InactiveUserComponent } from './core/inactive-user/inactive-user.component';

export const routes: Routes = [

  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'oss-insight/osspid-callback',
    component: OssInsightComponent
  },
  {
    path: 'inactive-user',
    component: InactiveUserComponent
  },
  // {
  //   path: 'tv-dashboard',
  //   component: TvdashboardComponent
  // },
  // {
  //   path: 'preview-report',
  //   component: ReportExplorerComponent,
  //   loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule),
  //   canActivate: [AuthGurd],
  // },
  /*{
    path: 'tv-chart',
    component: TvChartComponent
  },*/
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGurd],
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      }
      // ,
      // {
      //   path: 'settings',
      //   loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
      // }
    ]
  },

  {
    path: 'preview-report',
    loadChildren: () => import('./modules/preview-report/preview-report.module').then(m => m.PreviewReportModule),
    canActivate: [AuthGurd]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
