export class RequestData {
  id: string;
  oauth_token: string;
  oauth_data: string;
  ipAddress: string;
  userAgent: string;
  token: string;
  position: number;
  filter_key: string;
  dashboardId: string;

}
