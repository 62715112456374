import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ApiEndpoints } from './../api-endpoints';
import { OssPidRequest } from '../model/oss-pid-request';

@Injectable({
    providedIn: 'root'
})
export class OssService {
  apiEndpoints = new ApiEndpoints();
    constructor(private httpClient: HttpClient) {}
    ossVerification(ossPidRequest: any): Observable<any> {
        return this.httpClient.post(this.apiEndpoints.ossVerificationUrl, ossPidRequest);
    }

    ossLogout(ossPidRequest: any): Observable<any> {
        return this.httpClient.post(this.apiEndpoints.ossLogOutUrl, ossPidRequest);
    }

    userStatusCheck(ossPidRequest: any) {
      return this.httpClient.post(this.apiEndpoints.statusCheckUrl, ossPidRequest);
    }
}
