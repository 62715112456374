import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiEndpoints } from '../../api-endpoints';
import * as configureJsonData from "../../../assets/jsonData/configureData.json";

import { WebQrLoginDialogComponent } from '../web-qr-login-dialog/web-qr-login-dialog.component';
import { MatDialog } from '@angular/material';
import { MatSnackBar } from "@angular/material/snack-bar";
import { MobileBrowserDetectService } from 'src/app/modules/shared/report/service/mobile-browser-detect.service';
import { SuggestionSnackbarComponent } from 'src/app/modules/shared/suggestion-snackbar/suggestion-snackbar.coponent';
import { UtilService } from 'src/app/utils/util.service';
import { EmailLoginDialogComponent } from '../email-login-dialog/email-login-dialog.component';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  // loginMethod;
  jsonData;
  osspid: boolean = true;
  emailOTP: boolean = false;
  mobileAPP: boolean = true;
  showAdditionalInfo: boolean = true;
  showClientLogo: boolean = false;

  private apiEndpoints = new ApiEndpoints();

  constructor(private authService: AuthService,
    private httpClient: HttpClient,
    private dialog: MatDialog,
    private mobileBrowserDetectService: MobileBrowserDetectService,
    private snackBar: MatSnackBar,
    public util: UtilService,
  ) {
    // this.qproLoginForm = fb.group({
    //   userName: ['', Validators.required],
    //   password: ['', Validators.required]
    // });

    const mobileBrowser = this.mobileBrowserDetectService.isMobileBrowser();

    if (mobileBrowser) {
      this.showAdditionalInfo = false;

      this.snackBar.openFromComponent(SuggestionSnackbarComponent, {
        data: '',
        verticalPosition: 'top',
        horizontalPosition: 'center',
        // duration: 10000,
      });
    }
  }

  ngOnInit() {
    this.readConfigData();
    this.util.setTitle('Insightdb - Login');
  }

  openWebQrLoginDialog() {
    const dialogRef = this.dialog.open(WebQrLoginDialogComponent, {
      width: '800px',
      height: '450px',
      panelClass: 'custom-dialog-container'
    });
  }

  getCallbackData(): Observable<any> {
    const observable = new Subject<any>();
    this.httpClient.get(this.apiEndpoints.ossCallbackUrl).subscribe(
      response => {
        observable.next(response);
      }, error => {
        observable.next(error);
      }
    );
    return observable;
  }

  login() {
    this.authService.loginWithOssId().subscribe(
      response => {
        window.location.href = response.data.resourceAccessUrl;
        // window.location.href = 'https://osspid.org/osspid-client/auth?client_id=159617a323cdb0dfd8025814c2676f202c1e513b&cs=ZTA3MDgyZTRmYWFlN2Q4ZTZiYmU0Y2JiNTY4MGI2YWRmNDQ2MDUzOTo6WlRBM01EZ3laVFJtWVdGbE4yUTRaVFppWW1VMFkySmlOVFk0TUdJMllXUm1ORFEyTURVek9TTWpYMkpoZEE9PQ&callback_url=http://localhost:4200/oss-insight/osspid-callback';
        this.getCallbackData();
      }, error => console.log(error)
    );
  }

  openEmailInputDialog() {
    //420px * 220px OtpInputDialogComponent EmailInputDialogComponent
    const dialogRef = this.dialog.open(EmailLoginDialogComponent, {
      width: '420px',
      height: '240px',
      panelClass: 'custom-dialog-container'
    });
  }

  readConfigData() {
    this.httpClient.get('/insight_resource/configureData.json').subscribe(res => {
      this.jsonData = res;
      // console.log('config data: ');
      // console.log(this.jsonData);
      if (this.jsonData) {
        if (this.jsonData.provider) {
          this.osspid = this.jsonData.provider.osspid;
          this.emailOTP = this.jsonData.provider.emailOTP;
          this.mobileAPP = this.jsonData.provider.mobileAPP;
        }

        const clientLogo = this.jsonData.clientLogo;
        if (clientLogo) {
          this.showClientLogo = true;
        }
      }
    }
      , error => console.log('config file not found'));
  }
}
