import { ApiEndpoints } from '../../api-endpoints';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../utils/oss-helper.service";
const insightDashboardServiceEndPoint = 'dashboard-obj/get-data';
const insightDashboardServiceEndPointError = 'dashboard-obj/info';
const serviceInfoUrl = 'common/version';
export class RequestUtilService {
    constructor(http, ossHelperService) {
        this.http = http;
        this.ossHelperService = ossHelperService;
        this.apiEndpoints = new ApiEndpoints();
    }
    enableDevice(url, rb) {
        return this.http.post(url, rb);
    }
    getChartResponseData(url, rb) {
        const componentWiseRequest = this.ossHelperService.buildRequestBodyForComponentWiseData(rb);
        return this.http.post(url, componentWiseRequest);
        /*
        Initially only dashboard obj sent with req body.
        After changing api,
        common req body with dashboard id and filter key sent for component wies request.
        */
        // return this.http.post(url, rb);
    }
    getChartData(dashBoardObj) {
        let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.dashboardServiceUrl + insightDashboardServiceEndPoint;
        const data = this.ossHelperService.buildRequestBodyForChartData(dashBoardObj);
        return this.http.post(fullUrl, data);
    }
    getChartDataForInside(dbObjId) {
        let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.authServiceUrl + insightDashboardServiceEndPointError;
        const data = this.ossHelperService.buildRequestBodyForChartDataInside(dbObjId);
        return this.http.post(fullUrl, data);
    }
    getChartInsideData(dashBoardObj) {
        let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.dashboardServiceUrl + insightDashboardServiceEndPoint;
        const data = this.ossHelperService.buildRequestBodyForChartInsideData(dashBoardObj);
        return this.http.post(fullUrl, data);
    }
    getChartInsideDataTest(dashBoardObj) {
        let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.dashboardServiceUrl + insightDashboardServiceEndPoint;
        const data = this.ossHelperService.buildRequestBodyForChartInsideData(dashBoardObj);
        return this.http.post(fullUrl, data).pipe(map(value => value));
    }
    getDashboardServiceInfo() {
        let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.dashboardServiceUrl + serviceInfoUrl;
        return this.http.get(fullUrl).pipe(map(value => value));
    }
    getUtilityServiceInfo() {
        let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.utilityServiceUrl + serviceInfoUrl;
        return this.http.get(fullUrl).pipe(map(value => value));
    }
    getCoreServiceInfo() {
        let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.authServiceUrl + serviceInfoUrl;
        return this.http.get(fullUrl).pipe(map(value => value));
    }
    getNotificationServiceInfo() {
        let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.notificationServiceUrl + serviceInfoUrl;
        return this.http.get(fullUrl).pipe(map(value => value));
    }
}
RequestUtilService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RequestUtilService_Factory() { return new RequestUtilService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.OssHelperService)); }, token: RequestUtilService, providedIn: "root" });
