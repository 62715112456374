<div>
  <!-- [style.backgroundImage]="'url(assets/img/brand/background.png)'" -->
  <div
    class="panel panel-default"
    style="padding-left: 8%; padding-right: 5%; padding-top: 2%"
  >
    <div class="row" id="insightdbLogoDiv">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <!-- <img
          src="assets/img/brand/Logo12.png"
          alt="Logo"
          style="max-width: 80%; height: 70%"
          (click)="this.util.openInsightdbPage()"
        /> -->
      </div>
    </div>
    <div class="flex-row-wrap-container">
      <div id="descriptionDiv" style="margin-top: 24px" *ngIf="false">
        <div class="row">
          <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
            <p class="c-login-desc">
              InsightDB, a graphical tool is built for professionals to make
              decisions based on data. A powerful dashboard engine to discover
              data from various systems that are used by organizations to run
              daily operations.
            </p>
          </div>
        </div>
        <a
          class="btn btn-outline-primary"
          href="https://ba-systems.com/insightdb"
          target="_blank"
          >Learn More</a
        >
      </div>
      <br />
      <div class="row flex-row-wrap-container" id="loginOptionWithImageDiv">
        <div
          id="insightdbImageDiv"
          class="col-lg-7 col-md-7 col-xs-12 col-sm-12 d-flex justify-content-lg-start justify-content-md-start justify-content-sm-center mb-4 mb-md-0 flex-column"
          *ngIf="showAdditionalInfo"
        >
          <img
            src="/insight_resource/client_logo.png"
            alt="Client Logo"
            class="img-fluid"
            *ngIf="showClientLogo"
          />
          <div class="row" style="padding-top: 12%" *ngIf="!showClientLogo">
            <div class="col-12">
              <h2>
                Ultimate business solution that turns your data into decisions
              </h2>
            </div>
            <div class="col-12">
              <p class="c-login-desc">
                InsightDB, a graphical tool is built for professionals to make
                decisions based on data. A powerful dashboard engine to discover
                data from various systems that are used by organizations to run
                daily operations.
              </p>
            </div>
            <div class="col-12">
              <a
                class="btn btn-outline-primary"
                href="https://ba-systems.com/product/insightdb"
                target="_blank"
                >Learn More</a
              >
            </div>
          </div>
        </div>

        <div
          class="col-lg-5 col-md-5 col-xs-12 col-sm-12 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-center"
          id="loginOptionDiv"
        >
          <div class="shadow-lg p-3 mb-5 bg-white c-login-div">
            <div class="container">
              <div class="text-center" style="padding-top: 20px">
                <!-- max-width: 60%; height: 50% -->
                <img src="assets/img/brand/Logo12.png" alt="Logo" style="max-width: 20%; height: 17%"
                  (click)="this.util.openInsightdbPage()" />
              </div>
              <div class="text-center" style="padding-top: 40px">
                <span class="c-login-div-title">Login to Insightdb</span>
              </div>

              <ng-template [ngIf]="osspid">
                <div class="text-center" style="padding-top: 20px">
                  <button
                    type="button"
                    class="c-login-btn-osspid c-login-btn-osspid-primary btn-icon-text"
                    (click)="login()"
                  >
                    <i class="icon_lock icon_settings"></i>
                    <span class="c-span">With OSSPID</span>
                  </button>
                </div>

                <div class="text-center" style="padding-top: 5px">
                  <span class="c-login-register-text"
                    >Don't have an account?
                    <a href="https://osspid.org/user/create" target="_blank"
                      >Register</a
                    ></span
                  >
                </div>
              </ng-template>

              <div
                class="text-center"
                style="padding-top: 10px"
                *ngIf="osspid && mobileAPP"
              >
                <p class="c-login-line-with-text">
                  <span class="c-login-line-with-text-span">or</span>
                </p>
              </div>

              <div
                class="text-center"
                style="padding-top: 10px"
                *ngIf="mobileAPP"
              >
                <button
                  type="button"
                  class="c-login-btn-app c-login-btn-app-primary btn-icon-text"
                  (click)="openWebQrLoginDialog()"
                >
                  <i class="icon_qr icon_settings"></i>
                  <span class="c-span">With Insightdb app</span>
                </button>
              </div>

              <div
                class="text-center"
                style="padding-top: 10px"
                *ngIf="(osspid || mobileAPP) && emailOTP"
              >
                <p class="c-login-line-with-text">
                  <span class="c-login-line-with-text-span">or</span>
                </p>
              </div>

              <div
                class="text-center"
                style="padding-top: 10px"
                *ngIf="emailOTP"
              >
                <button
                  type="button"
                  class="c-login-btn-app c-login-btn-app-primary btn-icon-text"
                  (click)="openEmailInputDialog()"
                >
                  <i class="icon_qr icon_settings"></i>
                  <span class="c-span">With Email</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br /><br />
    <div class="row" style="margin-bottom: 100px">
      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
        <div class="row">
          <div class="col-6 col-md-3 ios-store-div">
            <img
              src="assets/img/brand/small_ios_store_3.png"
              alt="App Store"
              class="img-fluid"
              class="c-login-btn-image"
              (click)="this.util.openIosAppDownloadPage()"
            />
          </div>
          <div class="col-6 col-md-3 play-store-div">
            <img
              src="assets/img/brand/small_play_store_3.png"
              alt="Google Play Store"
              class="img-fluid"
              class="c-login-btn-image"
              (click)="this.util.openAndroidAppDownloadPage()"
            />
          </div>
          <div class="col-12 col-md-3 text-center">
            <img
              src="assets/img/brand/Group 315_1.png"
              alt="Browsers"
              class="img-fluid"
              class="c-login-btn-image-group"
            />
          </div>
        </div>
        <!-- <img src="assets/img/brand/Group 308.png" alt="App Store" class="img-fluid" class="c-login-btn-image" />
        <img src="assets/img/brand/Group 307.png" alt="Google Play" class="img-fluid" class="c-login-btn-image" />
        <img src="assets/img/brand/Group 306.png" alt="Chrome" class="img-fluid" class="c-login-btn-image" />
        <img src="assets/img/brand/Group 305.png" alt="Safari" class="img-fluid" class="c-login-btn-image" />
        <img src="assets/img/brand/Group 304.png" alt="Edge" class="img-fluid" class="c-login-btn-image" />
        <img src="assets/img/brand/Group 303.png" alt="Firefox" class="img-fluid" class="c-login-btn-image" /> -->
        <!-- <a type="button" class="c-button c-button-primary btn-icon-text"><i class="icon_apple icon_settings"></i><span
            class="c-span">App
            Store</span></a>
        <a type="button" class="c-button c-button-primary btn-icon-text"><i
            class="icon_google_play icon_settings"></i><span class="c-span">Google
            Play</span></a>
        <a type="button" class="c-button c-button-primary btn-icon-text"><i class="icon_chrome icon_settings"></i><span
            class="c-span">Chrome</span></a>
        <a type="button" class="c-button c-button-primary btn-icon-text"><i class="icon_safari icon_settings"></i><span
            class="c-span">Safari</span></a>
        <a type="button" class="c-button c-button-primary btn-icon-text"><i class="icon_edg icon_settings"></i><span
            class="c-span">Edge</span></a>
        <a type="button" class="c-button c-button-primary btn-icon-text"><i class="icon_firefox icon_settings"></i><span
            class="c-span">Firefox</span></a> -->
      </div>
    </div>
  </div>
  <div>
    <!-- <img src="assets/img/brand/Footer.png" alt="BA" style="width: 100%;"> -->
    <footer style="text-align: center">
      <a
        target="_blank"
        href="https://ba-systems.com/product/insightdb"
        style="color: black; font-style: italic"
      >
        &nbsp;InsightDB &copy;&nbsp; Business Automation Ltd. 2022
      </a>
    </footer>
  </div>
</div>

<!-- <main class="main d-flex align-items-center">
  <div class="container" style="padding: 3%">
    <div class="row" *ngIf="loginMethod ==='OSSPID'">
      <div class="col-md-8 mx-auto">
        <div class="card p-4">
          <div class="card-body text-center">
            <div style="height: 40px;">
              <img src="assets/img/brand/logo4.png" alt="BAT" height="80">
            </div>
            <br /><br />
            <p class="text-muted">Sign In to your account</p>
            <div class="row">
              <div class="col-5">
                <div style="display: flex;align-items: center;justify-content: center;height: 128px;">
                  <button type="button" (click)="login()" class="btn btn-primary mx-auto">Login with OSS
                    PID</button>
                </div>
              </div>
              <div class="col-2" style="display: flex;align-items: center;justify-content: center;height: 128px;">
                <mat-divider [vertical]="true" style="height: 128px;"></mat-divider>
              </div>
              <div class="col-5" style="display: flex;align-items: center;justify-content: center;height: 128px;">
                <button type="button" (click)="openWebQrLoginDialog()" class="btn btn-primary mx-auto">Login via
                  InsightDB App</button>
              </div>
            </div>
          </div>
          <div class="logo" style="height: 25px;">
            <img src="assets/img/brand/business_automation.png" alt="BAT" height="25" style="float:right;">
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="loginMethod ==='QPRO'">
      <div class="col-md-5 mx-auto">
        <div class="card p-4">
          <div class="logo" style="height: 25px;">
            <img src="assets/img/brand/logoQ.png" alt="BAT" height="40">
          </div>
          <div class="card-body text-center">
            <p class="text-muted">Sign In to your account</p>
            <div class="row">
              <div class="col-12">
                <mat-card-content>
                  <form [formGroup]="qproLoginForm" (ngSubmit)="qProLogin()">
                    <p>
                      <mat-form-field style="width:280px">
                        <input type="text" matInput placeholder="Enter the Username" formControlName="userName">
                      </mat-form-field>
                    </p>

                    <p>
                      <mat-form-field style="width:280px">
                        <input type="password" matInput placeholder="Enter the Password" formControlName="password">
                      </mat-form-field>
                    </p>

                    <div class="btn btn-primary btn-icon-text">
                      <button type="submit" class="btn btn-success" mat-button>Login</button>
                    </div>
                  </form>
                </mat-card-content>
              </div>
            </div>
          </div>
          <div class="logo" style="height: 25px;">
            <img src="assets/img/brand/business_automation.png" alt="BAT" height="25" style="float:right;">
          </div>
        </div>
      </div>
    </div>
  </div>
</main> -->
