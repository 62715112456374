import { Directive, ComponentFactoryResolver, TemplateRef, ViewContainerRef, Input } from "@angular/core";
import { MatProgressSpinner } from "@angular/material";

/**
 * This directive swaps the attached template for a spinner based upon some expression
 * @example `<mat-icon *appSpinOn="isLoading"></mat-icon>`
 */
 @Directive({
    selector: '[appSpinOn]',
  })
  export class SpinOnDirective {
    private hasView = false;
  
    constructor(
      private componentFactoryResolver: ComponentFactoryResolver,
      private templateRef: TemplateRef<any>,
      private viewContainer: ViewContainerRef
    ) {}
  
    @Input() set appSpinOn(condition: boolean) {
      if (!condition && !this.hasView) {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      } else if (condition && this.hasView) {
        this.viewContainer.clear();
        this.addSpinner();
        this.hasView = false;
      }
    }
  
    addSpinner() {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(MatProgressSpinner);
      this.viewContainer.clear();
  
      const componentRef = this.viewContainer.createComponent<MatProgressSpinner>(componentFactory);
      componentRef.instance.diameter = 18;
      componentRef.instance.color = 'accent';
      componentRef.instance.mode = 'indeterminate';
    //   componentRef.instance._elementRef.nativeElement.classList.add('button-spinner');
      componentRef.instance._elementRef.nativeElement.setAttribute('style', 'vertical-align: middle; display: inline-block;');
    }
  }