import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";


@Component({
    selector: 'email-login-dialog',
    templateUrl: './email-login-dialog.component.html',
    styleUrls: ['./email-login-dialog.component.css']
})

export class EmailLoginDialogComponent implements OnInit {
    emailAddress: string = '';
    constructor(public dialogRef: MatDialogRef<EmailLoginDialogComponent>,) {}

    ngOnInit() {}

    receiveValidEmailAddress(emailAddress) {
        // console.log(emailAddress);
        this.emailAddress = emailAddress;
        this.dialogRef.updateSize("420px", "320px");
    }
}