<div class="container-fluid header-div-parent">
  <div class="header-div">
    <!-- height="20" logo2  -->
    <img
      alt="Insightdb"
      class="logo"
      style="margin-top: 15px"
      (click)="this.util.openInsightdbPage()"
    />
    <div class="traffic-div-container">
      <div class="traffic-div">
        <label
          *ngFor="let dashboardObj of dashboardObjectsPart1; let i = index"
          class="btn btn-light mx-1"
          [(ngModel)]="radioModel"
          btnRadio="{{ dashboardObj.id }}"
          id="{{ dashboardObj.id }}"
          (click)="navigateToDashboard()"
          >{{ dashboardObj.caption }}</label
        >

        <!-- (mouseover)="status.isOpen = true" (mouseleave)="status.isOpen = false" -->
        <div
          class="btn-group float-right"
          [isOpen]="status.isOpen"
          (isOpenChange)="change($event)"
          *ngIf="showMore"
          dropdown
        >
          <label type="button" class="btn btn-light mx-1" dropdownToggle>
            {{ moreText }}
          </label>
          <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
            <a
              class="dropdown-item"
              *ngFor="let dashboardObj of dashboardObjectsPart2; let i = index"
              [(ngModel)]="radioModel"
              btnRadio="{{ dashboardObj.id }}"
              id="{{ dashboardObj.id }}"
              (click)="navigateToDashboard()"
              >{{ dashboardObj.caption }}</a
            >
          </div>
        </div>
        <span class="px-1"></span>
      </div>
    </div>

    <ul class="right-menu" style="padding-left: 20px">
      <li class="nav-item dropdown" dropdown placement="bottom right">
        <a
          class="nav-link"
          data-toggle="dropdown"
          href="#"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          dropdownToggle
          (click)="(false)"
        >
          <i class="fa fa-navicon" style="font-size: 24px; color: gray"></i>
        </a>
        <div
          class="dropdown-menu dropdown-menu-right"
          *dropdownMenu
          aria-labelledby="simple-dropdown"
        >
          <!--<div class="dropdown-header text-center"><strong>Account</strong></div>-->
          <a class="dropdown-item" routerLink="/dashboard/profile">
            <img [src]="profileUrl" alt="Profile-Image" class="img-avatar" />
            Profile
          </a>
          <div class="dropdown-item" (click)="openAboutDialog()">
            <i class="fa fa-info-circle"></i> About
          </div>

          <div *ngIf="isAdmin" class="dropdown-item">
            <i class="fa fa-info-circle"></i
            ><a
              style="color: black; text-decoration: none"
              href="https://docs.google.com/document/d/1RLPeeW4psg-xzc9IHrYfB4hf-jbVSPOiDf229QRpFgk/edit?usp=sharing"
              target="_blank"
              >Documentation</a
            >
          </div>
          <div class="dropdown-item" (click)="logout()">
            <i class="fa fa-lock"></i> Logout
          </div>

          <div *ngIf="isAdmin">
            <div class="dropdown-header text-center" *ngIf="isEnableDataHub">
              <strong>Data Hub</strong>
            </div>
            <a
              class="dropdown-item"
              routerLink="/dashboard/api-objects"
              *ngIf="isEnableAPI"
            >
              <i class="fa fa-th-list"></i> API
            </a>
            <a
              class="dropdown-item"
              routerLink="/dashboard/api-bank-objects"
              *ngIf="isEnableAPIBANK"
            >
              <i class="fa fa-th-list"></i> API Bank
            </a>
            <a
              class="dropdown-item"
              routerLink="/dashboard/client-objects"
              *ngIf="isEnableAPIClient"
            >
              <i class="fa fa-th-list"></i> API Client
            </a>
            <div class="dropdown-header text-center" *ngIf="dashboardMenus">
              <strong>Presentation</strong>
            </div>
            <a
              class="dropdown-item"
              routerLink="/dashboard/dashboard-list"
              *ngIf="isEnableDashboards"
            >
              <i class="fa fa-th-list"></i> Dashboards
            </a>
            <a
              class="dropdown-item"
              routerLink="/dashboard/data-sources"
              *ngIf="isEnableDataSources"
            >
              <i class="fa fa-th-list"></i> Data Sources
            </a>
            <a
              class="dropdown-item"
              routerLink="/dashboard/notification-list"
              *ngIf="isEnableNotifications"
            >
              <i class="fa fa-th-list"></i> Notifications
            </a>
            <a
              class="dropdown-item"
              routerLink="/dashboard/dashboard-objects"
              *ngIf="isEnablePresentations"
            >
              <i class="fa fa-th-list"></i> Presentations
            </a>
            <a
              class="dropdown-item"
              routerLink="/dashboard/report-list"
              *ngIf="isEnableReports"
            >
              <i class="fa fa-th-list"></i> Reports
            </a>
            <a
              class="dropdown-item"
              routerLink="/dashboard/user-list"
              *ngIf="isEnableUsers"
            >
              <i class="fa fa-th-list"></i> Users
            </a>

            <a
              class="dropdown-item"
              routerLink="/dashboard/user-type-list"
              *ngIf="isEnableUserTypes"
            >
              <i class="fa fa-th-list"></i> User Types
            </a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>

<div class="app-body">
  <main class="main">
    <router-outlet></router-outlet>
  </main>
</div>

<!--<div style="background-color: #ff8c1a;" *ngIf="connectivityScrollMsg">-->
<!--<ng-marquee>-->
<!--<mark><h4><span> {{connectivityScrollMsgTitle}} </span> <span style="color: red;"> {{connectivityScrollMsg}} </span>-->
<!--</h4></mark>-->
<!--</ng-marquee>-->
<!--</div>-->

<br />
<app-footer>
  <div class="mx-auto">
    <span
      ><a
        href="https://ba-systems.com/product/insightdb"
        target="_blank"
        style="color: black; font-style: italic"
        >&nbsp;InsightDB &copy;&nbsp; Business Automation Ltd. 2022</a
      ></span
    >
  </div>
</app-footer>
