import { Injectable } from '@angular/core';
import { RegisteredUrl } from '../../../model/registered-url';
import { StorageService } from '../../login/storage.service';
import { CodeStatus } from '../../../model/code-status';
import { DashboardObject } from '../../../model/dashboard-object';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor(private storageService: StorageService) {
  }

  getRegisteredUrlData(): RegisteredUrl[] {
    return this.storageService.read('registered_url');
  }

  // getCodeStatusData(): CodeStatus[] {
  //   return this.storageService.read('code_status');
  // }

  getOauthData(): string {
    return this.storageService.read('oauth_data');
  }

  getOauthToken(): string {
    return this.storageService.read('oauth_token');
  }

  getJWTToken(): string{
    return this.storageService.read('jwt_token');
  }
  getExpireInJWTToken(): string{
    return this.storageService.read('jwt_expireIn');
  }

  getDashboardObjs(): DashboardObject[] {
    return this.storageService.read('dashboard_obj');
  }

  getDashboardFilterKey(): string {
    return this.storageService.read('dashboardFilter') === null ? 'this_month' : this.storageService.read('dashboardFilter');
  }

  getUserId(): string {
    return this.storageService.read('userId');
  }

  saveUserCredentials(oauthData: string, oauthToken: string) {
    this.storageService.save('oauth_data', oauthData);
    this.storageService.save('oauth_token', oauthToken);
  }

  saveUserId(userId: string): void {
    this.storageService.save('userId', userId);
  }

  setJWTToken(jwttoken: string): void{
    return this.storageService.save('jwt_token', jwttoken);
  }

  setExpireInJWTToken(expireIn:string): void{
    return this.storageService.save('jwt_expireIn', expireIn );
  }

  saveCallbackUrl(url: string) {
    this.storageService.save('callback_url', url);
  }

  // saveCodeStatus(codeStatusList: CodeStatus[]) {
  //   this.storageService.save('code_status', codeStatusList);
  // }

  saveRegisteredUrl(registeredUrlList: RegisteredUrl[]) {
    this.storageService.save('registered_url', registeredUrlList);
  }

  saveDashboardObjs(dashboardObjList: DashboardObject[]): void {
    this.storageService.save('dashboard_obj', dashboardObjList);
  }

  saveDashboardMenus(dashboardMenuList: any): void {
    this.storageService.save('dashboard_menu', dashboardMenuList);
  }

  getDashboardMenus() {
    return this.storageService.read('dashboard_menu');
  }


  saveDashboardFilterKey(st: string): void {
    // console.log('Timeline Filter === >>>', st);
    this.storageService.save('dashboardFilter', st);
  }

  saveCurrentDashboard(obj: DashboardObject) {
    this.storageService.save('currentDashboardObj', obj);
  }

  getCurrentDashboard(): DashboardObject {
    return this.storageService.read('currentDashboardObj');
  }

  saveAdmin(obj: boolean) {
    this.storageService.save('admin', obj);
  }

  getAdmin(): boolean {
    return this.storageService.read('admin');
  }

  saveIsTv(obj: boolean) {
    this.storageService.save('isTv', obj);
  }

  getIsTv(): boolean {
    return this.storageService.read('isTv');
  }

  saveReportFormData(obj: any) {
    this.storageService.save('reportFormData', obj);
  }

  getReportFormData(): any {
    return this.storageService.read('reportFormData');
  }

  saveReportExplorerData(tableData, displayedColumns, formFields, obj, reportTitle) {
    const reportExploreData = {
      tableData: tableData,
      displayedColumns: displayedColumns,
      formFields: formFields,
      params: obj.params,
      reportTitle: reportTitle
    }
    this.storageService.save('reportExploreData', reportExploreData);
  }

  getReportExploreData() {
    return this.storageService.read('reportExploreData');
  }

  saveUserInfoForEmailLogin(info) {
    this.storageService.save('userInfoByEmail', info);
  }

  getUserInfoForEmailLogin() {
    return this.storageService.read('userInfoByEmail');
  }
}
