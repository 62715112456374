import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { of, merge, timer } from 'rxjs';
import { mapTo, delay } from 'rxjs/operators';
import { UtilService } from 'src/app/utils/util.service';
import { EmailLoginService } from '../email-login-dialog/email-login.service';
import { LocalStorageService } from '../shared/service/local-storage-service';
import { DashboardObject } from 'src/app/model/dashboard-object';
import { OssHelperService } from 'src/app/utils/oss-helper.service';
import { MatSnackBar, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { OssService } from 'src/app/utils/oss.service';
import { AuthService } from '../login/auth.service';

@Component({
    selector: 'otp-input-dialog',
    templateUrl: './otp-input-dialog.component.html',
    styleUrls: ['./otp-input-dialog.component.css']
})

export class OtpInputDialogComponent implements OnInit {
    @Input() emailAddress;

    myForm: FormGroup;
    isLoading: boolean = false;
    showError: boolean = false;
    showOTPBtn: boolean = false;
    remainingTimeText: string = '60';
    contactSystemAdmin = 'Please contact system admin!!!';

    constructor(public fb: FormBuilder,
        private service: EmailLoginService,
        private localStorageService: LocalStorageService,
        public util: UtilService,
        private ossHelperService: OssHelperService,
        private ossService: OssService,
        private _snackBar: MatSnackBar,
        private router: Router,
        public dialogRef: MatDialogRef<any>,
        private authService: AuthService,
        ) { }

    ngOnInit() {
        this.initReactiveForm();
        this.updateTimer();
    }

    initReactiveForm() {
        this.myForm = this.fb.group({
            otpCode: ['', [Validators.required]],
        });
    }

    updateTimer() {
        let remainingTime = 60;
        const otpTimer = timer(0, 1000);
        const otpGenSubscription = otpTimer.subscribe(t => {
            this.remainingTimeText = '0:' + this.getRmeainingTimeText(--remainingTime);
            if (remainingTime == 0) {
                this.remainingTimeText = '';
                this.showOTPBtn = true;
                otpGenSubscription.unsubscribe();
            }
        });
    }

    resendOTPCode() {
        this.showOTPBtn = false;
        this.updateTimer();
    }

    getRmeainingTimeText(remainingTime) {
        return (remainingTime < 10) ? '0' + remainingTime : remainingTime;
    }

    sendEmailExistRequest() {
        const otpCode = this.myForm.controls['otpCode'].value;
        this.isLoading = true;
        this.showError = false;

        this.service.executeEmailLogin(this.emailAddress, otpCode, this.localStorageService.getUserInfoForEmailLogin())
        .subscribe(res => {
            this.isLoading = false;
            this.parseEmailLoginRes(res);
        }, error => {
            console.log(error);
            this.isLoading = false;
            this.util.showSnackBarMessage(this.contactSystemAdmin, this.util.TYPE_MESSAGE.ERROR_TYPE);
        });
    }

    parseEmailLoginRes(response) {
        if (response && response.status === 200 && response.data && response.data.oauth_data && response.data.auth_token) {
            this.saveUserCredentials(response.data);
            const ossPidRequest = this.ossHelperService.buildOssPidRequestLoginForCallBack();
            this.ossVerification(ossPidRequest);
            return;
        }
        this.util.showSnackBarMessage(this.contactSystemAdmin, this.util.TYPE_MESSAGE.ERROR_TYPE);
    }

    ossVerification(verificationObject: Object) {
        this.ossService.ossVerification(verificationObject).subscribe(
            response => {
                this.redirectAfterOssVerification(response);
            },
            error => {
                console.log(error);
                if (error && error.status === 400) {
                    this._snackBar.open("Please try after One minute", "", {
                        duration: 2000
                    });
                    this.router.navigate(['/login']);
                }
            }
        );
    }

    redirectAfterOssVerification(response) {
        if (response.data.isAdmin) {
            this.localStorageService.saveAdmin(response.data.isAdmin);
        } else {
            this.localStorageService.saveAdmin(false);
        }

        let dashboardObjList = new Array<DashboardObject>();
        dashboardObjList = response.data.dashboards;
        this.localStorageService.saveDashboardObjs(dashboardObjList);

        this.localStorageService.saveCurrentDashboard(dashboardObjList[0]);

        if (response.status === 200 && response.data.status === true) {
            this.dialogRef.close();
            this.router.navigate(['/dashboard']);
        } else if (response.status === 200 && response.data.status === false) {
            this.dialogRef.close();
            this.router.navigate(['/inactive-user']);
        } else {
            this.dialogRef.close();
            this.router.navigate(['/login']);
        }
    }

    saveUserCredentials(data) {
        const oauthData = data.oauth_data;
        const oauthToken = data.auth_token;
        this.authService.saveUserCredentials(oauthData, oauthToken);
    }
}