import { ApiEndpoints } from "src/app/api-endpoints";
import * as configureJsonData from "../../../assets/jsonData/configureData.json";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class EmailLoginService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.apiEndpoints = new ApiEndpoints();
    }
    /**
     * Send a request to client server to check provided email address exist
     * @returns
     */
    executeEmailExistReq(emailAddress) {
        return this.httpClient.get(configureJsonData.default.emailUserVerificationAPI.replace(':email', emailAddress));
    }
    sendOTP(emailAddress) {
        return this.httpClient.post(this.apiEndpoints.sendOTP, { email: emailAddress });
    }
    executeEmailLogin(emailAddress, otp, userInfo) {
        const reqBody = {
            email: emailAddress,
            otp: otp,
            user_info: {
                user_full_name: userInfo['name'],
                user_email: userInfo['email'],
                mobile: userInfo['mobile'],
                gender: userInfo['gender'],
                dob: userInfo['dob'],
            }
        };
        return this.httpClient.post(this.apiEndpoints.emailLogin, reqBody);
    }
}
EmailLoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EmailLoginService_Factory() { return new EmailLoginService(i0.ɵɵinject(i1.HttpClient)); }, token: EmailLoginService, providedIn: "root" });
