import { Subject } from 'rxjs';
import { ApiEndpoints } from '../../api-endpoints';
import * as i0 from "@angular/core";
import * as i1 from "./storage.service";
import * as i2 from "@angular/common/http";
import * as i3 from "@angular/router";
import * as i4 from "../../utils/oss-helper.service";
import * as i5 from "../../utils/oss.service";
import * as i6 from "../../utils/util.service";
export class AuthService {
    constructor(storageService, httpClient, router, ossHelperService, ossService, utilService) {
        this.storageService = storageService;
        this.httpClient = httpClient;
        this.router = router;
        this.ossHelperService = ossHelperService;
        this.ossService = ossService;
        this.utilService = utilService;
        this.apiEndpoints = new ApiEndpoints();
    }
    isLoginIn() {
        return this.storageService.read('oauth_token');
    }
    saveUserCredentials(oauthData, oauthToken) {
        this.storageService.save('oauth_data', oauthData);
        this.storageService.save('oauth_token', oauthToken);
    }
    logout() {
        const ossPidRequest = this.ossHelperService.buildOssPidRequestLogout();
        // const obj = this.utilService.buildOssPidRequestLogout(ossPidRequest);
        this.ossService.ossLogout(ossPidRequest).subscribe(response => {
            if (response.data.status === true) {
                this.storageService.clear();
                this.router.navigate(['/login']);
            }
        }, error => console.log(error));
    }
    loginWithOssId() {
        const observable = new Subject();
        this.httpClient.get(this.apiEndpoints.ossLoginUrl).subscribe(response => observable.next(response), error => observable.next(error));
        return observable;
    }
    executeQrCodeForWebLoginReq() {
        return this.httpClient.get(this.apiEndpoints.qrCodeForWebLoginUrl);
    }
    executeWebLoginStatusCheckReq(qrCode) {
        // console.log(`${this.apiEndpoints.qrCodeForWebLoginUrl}/${qrCode}`);
        return this.httpClient.get(`${this.apiEndpoints.qrCodeInfo}/${qrCode}`);
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.StorageService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.OssHelperService), i0.ɵɵinject(i5.OssService), i0.ɵɵinject(i6.UtilService)); }, token: AuthService, providedIn: "root" });
