/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./inactive-user.component";
import * as i2 from "@angular/router";
import * as i3 from "../login/auth.service";
var styles_InactiveUserComponent = [];
var RenderType_InactiveUserComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InactiveUserComponent, data: {} });
export { RenderType_InactiveUserComponent as RenderType_InactiveUserComponent };
export function View_InactiveUserComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "main", [["class", "main d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 11, "div", [["class", "container"], ["style", "padding-top: 15%"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 9, "div", [["class", "col-md-5 mx-auto"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 8, "div", [["class", "card p-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 7, "div", [["class", "card-body text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "h5", [["class", "text-danger"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Inactive user. Please contact system admin for activation"])), (_l()(), i0.ɵeld(8, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-warning mx-auto"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Logout"])), (_l()(), i0.ɵeld(12, 0, null, null, 0, "div", [["class", "col-4"]], null, null, null, null, null))], null, null); }
export function View_InactiveUserComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_InactiveUserComponent_0, RenderType_InactiveUserComponent)), i0.ɵdid(1, 8503296, null, 0, i1.InactiveUserComponent, [i2.Router, i3.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InactiveUserComponentNgFactory = i0.ɵccf("ng-component", i1.InactiveUserComponent, View_InactiveUserComponent_Host_0, {}, {}, []);
export { InactiveUserComponentNgFactory as InactiveUserComponentNgFactory };
