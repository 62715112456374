import { MatSnackBar } from '@angular/material';
import { ErrorAlertDialogComponent } from '../core/error-alert-dialog/error-alert-dialog.component';
import { TITLE_ERROR, TITLE_SUCCESS } from '../core/constants/oss-insight-constants';
import { DatasourceDataDialogComponent } from '../core/datasource-data-dialog/datasource-data-dialog.component';
import { InsightInfo } from '../model/insight-info';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "@angular/material/snack-bar";
export class UtilService {
    constructor(titleService, snackBar) {
        this.titleService = titleService;
        this.snackBar = snackBar;
        this.TYPE_MESSAGE = {
            SUCCESS_TYPE: 'success',
            WARN_TYPE: 'warn',
            ERROR_TYPE: 'error',
        };
    }
    setTitle(newTitle) {
        this.titleService.setTitle(newTitle);
    }
    getIpAddress() {
        return ('198.168.142.153');
    }
    getBrowserUserAgent() {
        return window.navigator.userAgent;
    }
    getDeviceId() {
        return '31241234235345';
    }
    getClientId() {
        return '159617a323cdb0dfd8025814c2676f202c1e513b';
    }
    getSecretKey() {
        return 'e07082e4faae7d8e6bbe4cbb5680b6adf4460539';
    }
    buildOssPidRequestBody(ossPidRequest) {
        return { osspidRequest: ossPidRequest };
    }
    buildDetailReqObj(detailObj) {
        return { osspidRequest: detailObj };
    }
    showReqRes(name, url, reqBody, res) {
    }
    convertPipeIncludedStringToArray(st) {
        const data = st.split('||');
        const dataArray = [];
        for (let j = 0; j < data.length; j++) {
            dataArray.push(data[j]);
        }
        return dataArray;
    }
    convertPipeIncludedStringToNumberArray(st) {
        const data = st.split('||');
        const dataArray = [];
        for (let j = 0; j < data.length; j++) {
            dataArray.push(Number(data[j]));
        }
        return dataArray;
    }
    convertPipeStringToValueArray(st) {
        const data = st.split('||');
        const dataArray = [];
        let x;
        for (let j = 0; j < data.length; j++) {
            x = data[j];
            dataArray.push(this.convertValueToString(x));
        }
        return dataArray;
    }
    convertValueToString(value) {
        let retString;
        if (value >= 1.0e+9) {
            retString = (Math.abs(Number(value)) / 1.0e+9).toFixed(2).toLocaleString() + ' Billion';
        }
        else if (value >= 1.0e+6) {
            retString = (Math.abs(Number(value)) / 1.0e+6).toFixed(2).toLocaleString() + ' Million';
        }
        else {
            retString = Math.abs(value).toLocaleString();
        }
        if (retString === '0') {
            retString = '';
        }
        return retString;
    }
    getDataFromArray(dataArray, index) {
        if (dataArray.length > 0) {
            return dataArray[index];
        }
        else {
            return '';
        }
    }
    buildParamText(resData) {
        const data = resData.data;
        let text;
        text = '';
        if (data) {
            if (data.__param) {
                text += data.__param + ' || ';
            }
            if (data.__param2) {
                text += data.__param2 + ' || ';
            }
        }
        if (resData.userEmail) {
            text += data.email + ' || ';
        }
        if (text) {
            text = text.substr(0, text.length - 3);
            text = 'with parameter ' + text;
        }
        return text;
    }
    showUpdateSuccess(dialog, data) {
        this.showMessage(dialog, TITLE_SUCCESS, data, false, '');
    }
    showUpdateUnsuccessful(dialog, msg, err) {
        this.showMessage(dialog, TITLE_ERROR, msg + "\n" + err, true, err);
    }
    showErrorMessage(dialog, msg) {
        this.showMessage(dialog, TITLE_ERROR, msg, false, '');
    }
    /**
     * @param title (title of the dialog)
     * @param details (details of the dialog)
     * @param shouldShow (false means 'Error Details' button will not be shown, true means it will be shows)
     */
    showMessage(dialog, title, details, shouldShow, errorDetails) {
        const dialogRef = dialog.open(ErrorAlertDialogComponent, {
            disableClose: true,
            width: '300px',
            height: '200px',
            data: {
                details: details,
                title: title,
                shouldShow: shouldShow,
                errorDetails: errorDetails
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            // console.log('The dialog was closed');
        });
    }
    showDataSourceData(dialog, title, details, shouldShow, errorDetails, databaseVersion, databaseTime, proxyServerVersion, sshTunnelServiceVersion) {
        const dialogRef = dialog.open(DatasourceDataDialogComponent, {
            disableClose: true,
            width: '600px',
            height: '480px',
            data: {
                details: details,
                title: title,
                shouldShow: shouldShow,
                errorDetails: errorDetails,
                databaseVersion: databaseVersion,
                databaseTime: databaseTime,
                proxyServerVersion: proxyServerVersion,
                sshTunnelServiceVersion: sshTunnelServiceVersion
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            // console.log('The dialog was closed');
        });
    }
    showErrorDetails(dialog, title, details, shouldShow, errorDetails) {
        const dialogRef = dialog.open(ErrorAlertDialogComponent, {
            disableClose: true,
            width: '500px',
            height: '500px',
            data: {
                details: details,
                title: title,
                shouldShow: shouldShow,
                errorDetails: errorDetails
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            // console.log('The dialog was closed');
        });
    }
    showErrorDetailsForVerifyError(dialog, title, details, shouldShow, errorDetails) {
        const dialogRef = dialog.open(ErrorAlertDialogComponent, {
            disableClose: true,
            width: '500px',
            height: '250px',
            data: {
                details: details,
                title: title,
                shouldShow: shouldShow,
                errorDetails: errorDetails
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            // console.log('The dialog was closed');
        });
    }
    replaceTitle(title, parentTitle, parentValue) {
        title = title.replace('$__parent_title', (parentTitle ? this.replaceTitle(parentTitle, '', '') : ''));
        title = title.replace('$__parent_value', (parentValue ? parentValue : ''));
        return title;
    }
    generateErrorMsg(error) {
        let response = '';
        let fieldErrors = error['error']['fieldErrors'];
        let generalErrors = error['error']['generalErrors'];
        if (fieldErrors) {
            let values = Object.keys(fieldErrors).map(key => fieldErrors[key]);
            for (var o of values) {
                response += o + '<br />';
            }
        }
        if (generalErrors) {
            for (var o of generalErrors) {
                response += o + '<br />';
            }
        }
        else {
            response = error['error']['message'];
        }
        console.log("final-response", response);
        return response;
    }
    convertToDate(data) {
        if (data) {
            let date = new Date(data);
            return date.toLocaleString('en-US', { month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
        }
        else {
            return data;
        }
    }
    convertToDateWithYear(data) {
        if (data) {
            let date = new Date(data);
            return date.toLocaleString('en-GB', { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', year: 'numeric', hour12: false, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
        }
        else {
            return data;
        }
    }
    errorInsightInfo() {
        let insightInfo = new InsightInfo();
        insightInfo.errorLog = true;
        return insightInfo;
    }
    errorHandler(error) {
        let errorLog = this.errorInsightInfo();
        if (error['status'] === 304) {
            errorLog.debugData = 'This resource already updated by other';
        }
        else {
            errorLog.debugData = this.generateErrorMsg(error);
        }
        return errorLog;
    }
    isNumericString(str) {
        if (typeof str != "string" || str === '')
            return false; // we only process strings!
        str = str.replace(/,/g, '');
        return !isNaN(+str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)); // ...and ensure strings of whitespace fail
    }
    openAndroidAppDownloadPage() {
        window.open("https://play.google.com/store/apps/details?id=com.bat.insight");
    }
    openIosAppDownloadPage() {
        window.open("https://apps.apple.com/us/app/insightdb/id1499189318");
    }
    openInsightdbPage() {
        window.open("https://ba-systems.com/product/insightdb");
    }
    closCurrentTab() {
        window.close();
    }
    showSnackBarMessage(message, type) {
        this.snackBar.open(message, '', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
            panelClass: [type === this.TYPE_MESSAGE.SUCCESS_TYPE ? 'mat-green-snackbar'
                    : (type == this.TYPE_MESSAGE.ERROR_TYPE ? 'mat-red-snackbar' : 'mat-orange-snackbar')]
        });
    }
}
UtilService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilService_Factory() { return new UtilService(i0.ɵɵinject(i1.Title), i0.ɵɵinject(i2.MatSnackBar)); }, token: UtilService, providedIn: "root" });
