import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs/index';
import { DashboardObject } from 'src/app/model/dashboard-object';
import { OssHelperService } from 'src/app/utils/oss-helper.service';
import { OssService } from 'src/app/utils/oss.service';
import { AuthService } from '../login/auth.service';
import { LocalStorageService } from '../shared/service/local-storage-service';

@Component({
  selector: 'web-qr-login-dialog',
  templateUrl: './web-qr-login-dialog.component.html',
  styleUrls: ['./web-qr-login-dialog.component.css']
})

export class WebQrLoginDialogComponent implements OnInit {
  qrdata = '';
  qrDataOid = '';

  qrGenSubscription: Subscription;
  checkWebLoginStatusSubscription: Subscription;

  constructor(private ossHelperService: OssHelperService,
    private ossService: OssService,
    private _snackBar: MatSnackBar,
    private router: Router,
    public dialogRef: MatDialogRef<any>,
    private localStorageService: LocalStorageService,
    private authService: AuthService,) { }

  ngOnInit() {
    const qrGenTimer = timer(0, 33000);
    this.qrGenSubscription = qrGenTimer.subscribe(t => {
      this.getQrCodeForWebLogin();
    });

    const checkWebLoginStatusTimer = timer(2731, 2731);
    this.checkWebLoginStatusSubscription = checkWebLoginStatusTimer.subscribe(t => {
      this.getWebLoginStatus();
    });
  }

  ngOnDestroy() {
    this.qrGenSubscription.unsubscribe();
    this.checkWebLoginStatusSubscription.unsubscribe();
  }

  getQrCodeForWebLogin() {
    this.authService.executeQrCodeForWebLoginReq()
      .subscribe(
        res => {
          if (res.status && res.status === 200 && res.data)
            this.qrdata = res.data.qr_code;
          this.qrDataOid = res.data.oid;
        }
        , error => console.log(error)
      );
  }

  getWebLoginStatus() {
    this.authService.executeWebLoginStatusCheckReq(this.qrDataOid).subscribe(
      res => this.parseWebLoginStatusCheckRes(res), error => console.log(error)
    );
  }

  parseWebLoginStatusCheckRes(res) {
    if (res.status === 200 && res.data && res.data.is_login) {
      this.saveUserCredentials(res.data);
      const ossPidRequest = this.ossHelperService.buildOssPidRequestLoginForCallBack();
      this.ossVerification(ossPidRequest);
    }
  }

  ossVerification(verificationObject: Object) {
    this.ossService.ossVerification(verificationObject).subscribe(
      response => {
        this.redirectAfterOssVerification(response);
      },
      error => {
        console.log(error);
        if (error && error.status === 400) {
          this._snackBar.open("Please try after One minute", "", {
            duration: 2000
          });
          this.router.navigate(['/login']);
        }
      }
    );
  }

  /**
   * Right now same code is present in #oss-insight.component for login via oss
   * If any change needed change in that file too
   * It will be better to re-use it in both file...
   * @param response
   */
  redirectAfterOssVerification(response) {
    if (response.data.isAdmin) {
      this.localStorageService.saveAdmin(response.data.isAdmin);
    } else {
      this.localStorageService.saveAdmin(false);
    }

    let dashboardMenuList = response.data.dashboardMenus;
    this.localStorageService.saveDashboardMenus(dashboardMenuList);

    let dashboardObjList = new Array<DashboardObject>();
    dashboardObjList = response.data.dashboards;
    this.localStorageService.saveDashboardObjs(dashboardObjList);

    this.localStorageService.saveCurrentDashboard(dashboardObjList[0]);

    if (response.status === 200 && response.data.status === true) {
      this.dialogRef.close();
      this.router.navigate(['/dashboard']);
    } else if (response.status === 200 && response.data.status === false) {
      this.dialogRef.close();
      this.router.navigate(['/inactive-user']);
    } else {
      this.dialogRef.close();
      this.router.navigate(['/login']);
    }
  }

  saveUserCredentials(data) {
    const oauthData = data.oauth_data;
    const oauthToken = data.auth_token;
    this.authService.saveUserCredentials(oauthData, oauthToken);
  }
}
