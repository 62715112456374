import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { PageEvent, MatPaginator, MatTableDataSource, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-datasource-data-dialog',
  templateUrl: './datasource-data-dialog.component.html',
  styleUrls: ['./datasource-data-dialog.component.css']
})
export class DatasourceDataDialogComponent implements OnInit {


  customMessage: string;
  testData: string;
  isDataAvailable: boolean = false;
  dataSize = 10;
  public pageSizeOptions = [5, 10, 20];
  pageEvent: PageEvent;
  paginatorLength: number;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource();
  displayedColumns: string[];
  proxyServerStatus: string;
  databaseVersion: string;
  databaseTime: string;
  proxyServerVersion: string;
  sshTunnelServiceVersion: string;


  constructor(public dialogRef: MatDialogRef<DatasourceDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    console.log("connection-verify", this.data.details);
    this.isDataAvailable = true;
    this.proxyServerVersion = this.data.details.proxyServerVersion
    this.proxyServerStatus = this.data.details.proxyServerStatus
    this.sshTunnelServiceVersion = this.data.details.sshTunnelServiceVersion
    this.databaseVersion = this.data.details.databaseVersion
    this.databaseTime = this.data.details.databaseTime
    this.setDataSourceData(this.data.details.data);
    this.setDisplayedColumnValue(this.data.details.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setDataSourceData(data: any) {
    this.dataSource.data = data;
  }

  onDetails() {
    this.data.details = this.customMessage;
  }

  setDisplayedColumnValue(data: any) {
    let keys = Object.keys(data[0]);
    let nextObjIdPosition = -1;

    $.each(keys, function (i, it) {
      // if (it === 'next_obj_id') {
      //   nextObjIdPosition = i;
      // }
    });

    let columns_obj = [];
    $.each(keys, function (i, it) {
      if (nextObjIdPosition != i) {
        columns_obj.push(it);
      }
    });
    this.displayedColumns = columns_obj;
  }

}
