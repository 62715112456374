import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { ApiEndpoints } from './api-endpoints';
import { StorageService } from './core/login/storage.service';
import { ProfileComponent } from './core/profile/profile.component';
import { DashboardService } from './modules/dashboard/service/dashboard.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'oss-dashboard';
  // checkMobileLoginStatusSubscription: Subscription;
  private apiEndpoints = new ApiEndpoints();

  constructor(private storageService: StorageService,
    private dashboardService: DashboardService,
    private router: Router,
    private renderer: Renderer2) {

  }
  ngAfterViewInit(): void {
    const loader = this.renderer.selectRootElement('#loader');
    this.renderer.setStyle(loader, 'display', 'none');

    window['ba_sw_id'] = 'e07b3002-b4bc-4657-96fc-93442a9cfc23';
    var s1 = document.createElement('script');
    s1.setAttribute('src', 'https://feedback.oss.net.bd/src/0.1.3/social_widget_link.js');
    document.body.appendChild(s1);
  }

  ngOnInit() {
    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
        let token = this.storageService.read('oauth_token');
        if (token == undefined) { // you can update this as per your key
          this.router.navigate(['/login']);
        } else {
          // this.setupLoginStatusCheckTimer();
        }
      }
    }, false);
  }

  // setupLoginStatusCheckTimer() {
  //   const checkMobileLoginStatusTimer = timer(2731, 2731); // TODO:activate later 2731
  //   this.checkMobileLoginStatusSubscription = checkMobileLoginStatusTimer.subscribe(t => {

  //     const fullUrl = this.apiEndpoints.profileInfoUrl;
  //     const reqBody = {};
  //     this.dashboardService.getDataForPostReq(fullUrl, reqBody).subscribe(
  //       response => {
  //       }, error => console.log(error));
  //   });
  // }

}
