/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./email-login-dialog.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../email-input-dialog/email-input-dialog.component.ngfactory";
import * as i3 from "../email-input-dialog/email-input-dialog.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../utils/util.service";
import * as i6 from "./email-login.service";
import * as i7 from "../shared/service/local-storage-service";
import * as i8 from "../otp-input-dialog/otp-input-dialog.component.ngfactory";
import * as i9 from "../otp-input-dialog/otp-input-dialog.component";
import * as i10 from "../../utils/oss-helper.service";
import * as i11 from "../../utils/oss.service";
import * as i12 from "@angular/material/snack-bar";
import * as i13 from "@angular/router";
import * as i14 from "@angular/material/dialog";
import * as i15 from "../login/auth.service";
import * as i16 from "@angular/common";
import * as i17 from "./email-login-dialog.component";
var styles_EmailLoginDialogComponent = [i0.styles];
var RenderType_EmailLoginDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmailLoginDialogComponent, data: {} });
export { RenderType_EmailLoginDialogComponent as RenderType_EmailLoginDialogComponent };
function View_EmailLoginDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "email-input-dialog", [], null, [[null, "emailAddressEmitter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("emailAddressEmitter" === en)) {
        var pd_0 = (_co.receiveValidEmailAddress($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_EmailInputDialogComponent_0, i2.RenderType_EmailInputDialogComponent)), i1.ɵdid(1, 114688, null, 0, i3.EmailInputDialogComponent, [i4.FormBuilder, i5.UtilService, i6.EmailLoginService, i7.LocalStorageService], null, { emailAddressEmitter: "emailAddressEmitter" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_EmailLoginDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "otp-input-dialog", [], null, null, null, i8.View_OtpInputDialogComponent_0, i8.RenderType_OtpInputDialogComponent)), i1.ɵdid(1, 114688, null, 0, i9.OtpInputDialogComponent, [i4.FormBuilder, i6.EmailLoginService, i7.LocalStorageService, i5.UtilService, i10.OssHelperService, i11.OssService, i12.MatSnackBar, i13.Router, i14.MatDialogRef, i15.AuthService], { emailAddress: [0, "emailAddress"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.emailAddress; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_EmailLoginDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "container"], ["style", "padding-top: 15px; padding-bottom: 15px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["style", "float: right;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [], [[1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).dialogRef.close(i1.ɵnov(_v, 3).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 606208, null, 0, i14.MatDialogClose, [[2, i14.MatDialogRef], i1.ElementRef, i14.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmailLoginDialogComponent_1)), i1.ɵdid(7, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmailLoginDialogComponent_2)), i1.ɵdid(9, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = true; _ck(_v, 3, 0, currVal_2); var currVal_3 = !_co.emailAddress; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.emailAddress; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 3).ariaLabel || null); var currVal_1 = i1.ɵnov(_v, 3).type; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_EmailLoginDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "email-login-dialog", [], null, null, null, View_EmailLoginDialogComponent_0, RenderType_EmailLoginDialogComponent)), i1.ɵdid(1, 114688, null, 0, i17.EmailLoginDialogComponent, [i14.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmailLoginDialogComponentNgFactory = i1.ɵccf("email-login-dialog", i17.EmailLoginDialogComponent, View_EmailLoginDialogComponent_Host_0, {}, {}, []);
export { EmailLoginDialogComponentNgFactory as EmailLoginDialogComponentNgFactory };
