<div>
  <div class="row">
       <img style="margin-left: 40%;" src="assets/img/brand/logo4.png" alt="Insight" height="80" class="logo"/>
  </div>
</div>
<br/>
<div class="row"> <!--row 1-->
  <div class="col-md-5">
    <div mat-dialog-content>
      <div class="mx-auto">
        <span><h5><a href="http://batworld.com/company-overview" target="_blank" style="color: black;font-style: italic">Company Overview</a></h5></span>
      </div>
      <div class="mx-auto">
        <span><h5><a href="http://support.batworld.com/" target="_blank" style="color: black;font-style: italic">Support</a></h5></span>
      </div>
    </div>
  </div>
  <div class ="col-md-1">
    <mat-divider vertical style="height:150px"></mat-divider>
  </div>
  <div class="col-md-6">
    <h6>version Info:</h6>
    <div mat-dialog-content>
      <h5 style="margin-left: 10px;">Web App : {{version}}</h5>
      <h5 style="margin-left: 10px;">{{dashboardServiceName +" : " +dashboardServiceVersion}}</h5>
      <h5 style="margin-left: 10px;">{{utiLityServicename + " : " + utilityServiceVersion}}</h5>
      <h5 style="margin-left: 10px;">{{coreServiceName +" : "+ coreServiceVersion}}</h5>
      <h5 style="margin-left: 10px;">{{notificationServiceName +" : "+ notificationServiceVersion}}</h5>
    </div>
  </div>
</div>
<br/><br/>
<button style="float: right" [mat-dialog-close]="true" type="button" mat-raised-button color="primary" class="closebtnColor">
<i class="fa fa-times"></i><span style="margin-left: 4px">Close</span></button>
