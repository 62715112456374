import { Injectable } from '@angular/core';
import { OssPidRequest } from '../model/oss-pid-request';
import { StorageService } from '../core/login/storage.service';
import { DetailReqBody } from '../model/detail-req-body';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ErrorAlertDialogComponent } from '../core/error-alert-dialog/error-alert-dialog.component';
import { TITLE_ERROR, TITLE_SUCCESS } from '../core/constants/oss-insight-constants';
import { LoginInfoBean } from '../modules/dashboard/model/login-info-bean';
import { LocalStorageService } from '../core/shared/service/local-storage-service';
import { DatasourceDataDialogComponent } from '../core/datasource-data-dialog/datasource-data-dialog.component';
import { InsightInfo } from '../model/insight-info';
import { InsightDialogComponent } from '../modules/admin-module/dialog/insight-dialog/insight-dialog.component';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  TYPE_MESSAGE = {
    SUCCESS_TYPE: 'success', //mat-green-snackbar
    WARN_TYPE: 'warn', //mat-orange-snackbar
    ERROR_TYPE: 'error', //mat-red-snackbar
  };

  constructor(private titleService?: Title, private snackBar?: MatSnackBar) { }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  public getIpAddress(): string {
    return ('198.168.142.153');
  }

  public getBrowserUserAgent(): string {
    return window.navigator.userAgent;
  }

  public getDeviceId(): string {
    return '31241234235345';
  }

  public getClientId(): string {
    return '159617a323cdb0dfd8025814c2676f202c1e513b';
  }

  public getSecretKey(): string {
    return 'e07082e4faae7d8e6bbe4cbb5680b6adf4460539';
  }

  buildOssPidRequestBody(ossPidRequest: OssPidRequest): Object {
    return { osspidRequest: ossPidRequest };
  }

  buildDetailReqObj(detailObj: DetailReqBody): Object {
    return { osspidRequest: detailObj };
  }

  showReqRes(name, url, reqBody, res): void {
  }

  convertPipeIncludedStringToArray(st: string): Array<any> {
    const data = st.split('||');
    const dataArray = [];
    for (let j = 0; j < data.length; j++) {
      dataArray.push(data[j]);
    }
    return dataArray;
  }

  convertPipeIncludedStringToNumberArray(st: string): Array<number> {
    const data = st.split('||');
    const dataArray = [];
    for (let j = 0; j < data.length; j++) {
      dataArray.push(Number(data[j]));
    }
    return dataArray;
  }

  convertPipeStringToValueArray(st: string): Array<any> {
    const data = st.split('||');
    const dataArray = [];
    let x;
    for (let j = 0; j < data.length; j++) {
      x = data[j];
      dataArray.push(this.convertValueToString(x));
    }
    return dataArray;
  }

  convertValueToString(value: number): string {
    let retString: string;
    if (value >= 1.0e+9) {
      retString = (Math.abs(Number(value)) / 1.0e+9).toFixed(2).toLocaleString() + ' Billion';
    } else if (value >= 1.0e+6) {
      retString = (Math.abs(Number(value)) / 1.0e+6).toFixed(2).toLocaleString() + ' Million';
    } else {
      retString = Math.abs(value).toLocaleString();
    }
    if (retString === '0') {
      retString = '';
    }

    return retString;
  }

  getDataFromArray(dataArray, index) {
    if (dataArray.length > 0) {
      return dataArray[index];
    } else {
      return '';
    }
  }

  buildParamText(resData: any): string {
    const data = resData.data;
    let text: string;
    text = '';
    if (data) {
      if (data.__param) {
        text += data.__param + ' || ';
      }
      if (data.__param2) {
        text += data.__param2 + ' || ';
      }
    }

    if (resData.userEmail) {
      text += data.email + ' || ';
    }
    if (text) {
      text = text.substr(0, text.length - 3);
      text = 'with parameter ' + text;
    }
    return text;
  }

  public showUpdateSuccess(dialog, data) {
    this.showMessage(dialog, TITLE_SUCCESS, data, false, '');
  }
  public showUpdateUnsuccessful(dialog, msg, err) {
    this.showMessage(dialog, TITLE_ERROR, msg + "\n" + err, true, err);
  }
  public showErrorMessage(dialog, msg) {
    this.showMessage(dialog, TITLE_ERROR, msg, false, '');
  }

  /**
   * @param title (title of the dialog)
   * @param details (details of the dialog)
   * @param shouldShow (false means 'Error Details' button will not be shown, true means it will be shows)
   */
  public showMessage(dialog, title, details, shouldShow, errorDetails): void {

    const dialogRef = dialog.open(ErrorAlertDialogComponent, {
      disableClose: true,
      width: '300px',
      height: '200px',
      data: {
        details: details,
        title: title,
        shouldShow: shouldShow,
        errorDetails: errorDetails
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }


  public showDataSourceData(dialog, title, details, shouldShow, errorDetails, databaseVersion, databaseTime, proxyServerVersion, sshTunnelServiceVersion): void {
    const dialogRef = dialog.open(DatasourceDataDialogComponent, {
      disableClose: true,
      width: '600px',
      height: '480px',
      data: {
        details: details,
        title: title,
        shouldShow: shouldShow,
        errorDetails: errorDetails,
        databaseVersion: databaseVersion,
        databaseTime: databaseTime,
        proxyServerVersion: proxyServerVersion,
        sshTunnelServiceVersion: sshTunnelServiceVersion
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

  public showErrorDetails(dialog, title, details, shouldShow, errorDetails): void {

    const dialogRef = dialog.open(ErrorAlertDialogComponent, {
      disableClose: true,
      width: '500px',
      height: '500px',
      data: {
        details: details,
        title: title,
        shouldShow: shouldShow,
        errorDetails: errorDetails
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

  public showErrorDetailsForVerifyError(dialog, title, details, shouldShow, errorDetails): void {

    const dialogRef = dialog.open(ErrorAlertDialogComponent, {
      disableClose: true,
      width: '500px',
      height: '250px',
      data: {
        details: details,
        title: title,
        shouldShow: shouldShow,
        errorDetails: errorDetails
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

  public replaceTitle(title, parentTitle, parentValue): string {
    title = title.replace('$__parent_title', (parentTitle ? this.replaceTitle(parentTitle, '', '') : ''));
    title = title.replace('$__parent_value', (parentValue ? parentValue : ''));
    return title;
  }

  public generateErrorMsg(error: any) {
    let response = '';
    let fieldErrors = error['error']['fieldErrors'];
    let generalErrors = error['error']['generalErrors'];

    if (fieldErrors) {
      let values = Object.keys(fieldErrors).map(key => fieldErrors[key]);
      for (var o of values) {
        response += o + '<br />';
      }
    }

    if (generalErrors) {
      for (var o of generalErrors) {
        response += o + '<br />';
      }
    } else {
      response = error['error']['message'];
    }
    console.log("final-response", response)
    return response;
  }

  convertToDate(data: any) {
    if (data) {
      let date = new Date(data);
      return date.toLocaleString('en-US', { month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
    } else {
      return data;
    }
  }

  convertToDateWithYear(data: any) {
    if (data) {
      let date = new Date(data);
      return date.toLocaleString('en-GB', { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', year: 'numeric', hour12: false, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
    } else {
      return data;
    }
  }


  errorInsightInfo(): InsightInfo {
    let insightInfo = new InsightInfo();
    insightInfo.errorLog = true;
    return insightInfo;
  }

  errorHandler(error): InsightInfo {
    let errorLog = this.errorInsightInfo();
    if (error['status'] === 304) {
      errorLog.debugData = 'This resource already updated by other';
    } else {
      errorLog.debugData = this.generateErrorMsg(error);
    }
    return errorLog;
  }

  isNumericString(str: string): boolean {
    if (typeof str != "string" || str === '') return false // we only process strings!
    str = str.replace(/,/g, '');
    return !isNaN(+str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

  openAndroidAppDownloadPage() {
    window.open("https://play.google.com/store/apps/details?id=com.bat.insight");
  }

  openIosAppDownloadPage() {
    window.open("https://apps.apple.com/us/app/insightdb/id1499189318");
  }

  openInsightdbPage() {
    window.open("https://ba-systems.com/product/insightdb");
  }

  closCurrentTab() {
    window.close();
  }

  showSnackBarMessage(message: string, type: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: 5000,
      panelClass: [type === this.TYPE_MESSAGE.SUCCESS_TYPE ? 'mat-green-snackbar'
        : (type == this.TYPE_MESSAGE.ERROR_TYPE ? 'mat-red-snackbar' : 'mat-orange-snackbar')]
    });
  }
}
