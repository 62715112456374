import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { ApiEndpoints } from "src/app/api-endpoints";

import * as configureJsonData from "../../../assets/jsonData/configureData.json";

@Injectable({
    providedIn: 'root'
})
export class EmailLoginService {
    apiEndpoints = new ApiEndpoints();

    constructor(private httpClient: HttpClient,) { }

    /**
     * Send a request to client server to check provided email address exist
     * @returns 
     */
    executeEmailExistReq(emailAddress): Observable<any> {
        return this.httpClient.get(configureJsonData.default.emailUserVerificationAPI.replace(':email', emailAddress));
    }

    sendOTP(emailAddress: string): Observable<any> {
        return this.httpClient.post(this.apiEndpoints.sendOTP, { email: emailAddress });
    }

    executeEmailLogin(emailAddress, otp:string, userInfo): Observable<any> {
        const reqBody = {
            email: emailAddress,
            otp: otp,
            user_info: {
                user_full_name: userInfo['name'],
                user_email: userInfo['email'],
                mobile: userInfo['mobile'],
                gender: userInfo['gender'],
                dob: userInfo['dob'],
            }
        };
        return this.httpClient.post(this.apiEndpoints.emailLogin, reqBody);
    }
}