import { Component, OnDestroy, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { navItems } from '../../_nav';
import { AuthService } from '../login/auth.service';
import { DashboardService } from '../../modules/dashboard/service/dashboard.service';
import { OssHelperService } from '../../utils/oss-helper.service';
import { Profile } from '../profile/profile';
import { ApiEndpoints } from '../../api-endpoints';
import { DomSanitizer } from '@angular/platform-browser';
import { DashboardObject } from '../../model/dashboard-object';
import { HelperService } from './helper.service';
import { LocalStorageService } from '../shared/service/local-storage-service';
import { Router } from '@angular/router';
import { DashboardComponent } from '../../modules/dashboard/dashboard/dashboard.component';
import { DashboardObjRefreshDataService } from '../shared/service/data-service';
import { AboutDialogComponent } from 'src/app/modules/admin-module/dialogBox/about-dialog/about-dialog.component';
import { MatDialog } from '@angular/material';
import { UtilService } from 'src/app/utils/util.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css']
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {

  avterMaleImgUrl = '../../../../assets/img/avatars/male_01.jpg';
  radioModel: string;
  navItems = navItems;
  sidebarMinimized = true;
  private changes: MutationObserver;
  element: HTMLElement;
  responseData: any;
  dashboardObjects: DashboardObject[] = [];
  dashboardObjectsPart1: DashboardObject[] = [];
  dashboardObjectsPart2: DashboardObject[] = [];
  isAdmin: boolean;
  showMore: boolean = false;
  dashboardMenus: any;
  isEnableDashboardMenu: any;
  isEnableAPI = false;
  isEnableAPIBANK = false;
  isEnableAPIClient = false;
  isEnableDashboards = false;
  isEnableDataSources = false;
  isEnableNotifications = false;
  isEnablePresentations = false;
  isEnableReports = false;
  isEnableUserTypes = false;
  isEnableUsers = false;
  isEnableDataHub = false;
  private apiEndpoints = new ApiEndpoints();
  profile: Profile = new Profile();
  profileUrl: string;
  allowedSize: number = 9;
  status: { isOpen: boolean } = { isOpen: false };
  moreText: string = 'More...';

  constructor(private authService: AuthService,
    private localStorageService: LocalStorageService,
    private dashboardService: DashboardService,
    private router: Router,
    private dataService: DashboardObjRefreshDataService,
    private dialog: MatDialog,
    public util: UtilService,
    @Inject(DOCUMENT) _document?: any,
  ) {
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(this.element as Element, {
      attributes: true,
      attributeFilter: ['class']
    });
  }

  ngOnInit(): void {
    this.allowedSize = Math.floor((screen.width / 148));
    if (!this.allowedSize) {
      this.allowedSize = 2;
    }
    this.isAdmin = this.localStorageService.getAdmin();
    // console.log('isAdmin ----------', this.isAdmin);
    this.getDashboardMenus();
    this.dataService.data.subscribe(data => {
      this.dashboardObjects = data;
      this.getDashboardObjects();
    });
    this.getDashboardObjects();
    this.getProfileInfo();
  }

  getDashboardObjects() {
    this.dashboardObjects = this.localStorageService.getDashboardObjs();
    this.dashboardObjects.forEach(e => {
      if (e.caption.length >= 15) {
        e.caption = e.caption.substr(0, 14) + '.';
      }
    });
    this.createDashboardObjParts();
    this.setCurrentDashboradRadioModel();
  }

  getDashboardMenus() {
    this.dashboardMenus = this.localStorageService.getDashboardMenus();
    if (!this.dashboardMenus) {
      if (this.isAdmin) {
        this.util.showSnackBarMessage('Please logout from system & login again'
          , this.util.TYPE_MESSAGE.WARN_TYPE);
      }
      return;
    }

    //this.isEnableDashboardMenu = this.dashboardMenus.filter( e => e.isEnable == 1);

    this.isEnableAPI = this.dashboardMenus.some(e => e.menuTitle === 'API' && e.isEnable === 1);
    this.isEnableAPIBANK = this.dashboardMenus.some(e => e.menuTitle === 'API BANK' && e.isEnable === 1);
    this.isEnableAPIClient = this.dashboardMenus.some(e => e.menuTitle === 'API Client' && e.isEnable === 1);
    this.isEnableDashboards = this.dashboardMenus.some(e => e.menuTitle === 'Dashboards' && e.isEnable === 1);
    this.isEnableDataSources = this.dashboardMenus.some(e => e.menuTitle === 'Data Sources' && e.isEnable === 1);
    this.isEnableNotifications = this.dashboardMenus.some(e => e.menuTitle === 'Notifications' && e.isEnable === 1);
    this.isEnablePresentations = this.dashboardMenus.some(e => e.menuTitle === 'Presentations' && e.isEnable === 1);
    this.isEnableReports = this.dashboardMenus.some(e => e.menuTitle === 'Reports' && e.isEnable === 1);
    this.isEnableUserTypes = this.dashboardMenus.some(e => e.menuTitle === 'User Types' && e.isEnable === 1);
    this.isEnableUsers = this.dashboardMenus.some(e => e.menuTitle === 'Users' && e.isEnable === 1);
    this.isEnableDataHub = this.dashboardMenus.some(e => e.menuTitle === 'Data Hub' && e.isEnable === 1);

  }

  change(value: boolean): void {
    this.status.isOpen = value;
    // console.log(this.status.isOpen);
  }

  createDashboardObjParts() {
    let endIndex = (this.dashboardObjects.length >= this.allowedSize) ? this.allowedSize : this.dashboardObjects.length;

    this.dashboardObjectsPart1 = this.dashboardObjects.slice(0, endIndex);
    if (this.dashboardObjects.length > this.allowedSize) {
      this.showMore = true;
      this.dashboardObjectsPart2 = this.dashboardObjects.slice(endIndex, this.dashboardObjects.length);
    }

    // console.log(this.dashboardObjectsPart1);
    // console.log(this.dashboardObjectsPart2);
  }

  navigateToDashboard() {

    const dashboardObject = this.dashboardObjects.find(x => x.id.toString() === this.radioModel);
    this.localStorageService.saveCurrentDashboard(dashboardObject);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/dashboard']);
    this.util.setTitle(`Insightdb - ${dashboardObject.caption}`);
  }

  trackById(index, dashboardObj) {
    return dashboardObj.id;
  }

  setCurrentDashboradRadioModel() {
    const currentDashboard: DashboardObject = this.localStorageService.getCurrentDashboard();
    if (currentDashboard) {
      this.radioModel = currentDashboard.id.toString();
      let selectedIndex = this.dashboardObjects.findIndex(x => x.id.toString() === this.radioModel);
      if (selectedIndex >= this.allowedSize) {
        this.moreText = `${currentDashboard.caption}...`;
      } else {
        this.moreText = 'More...';
      }

      this.util.setTitle(`Insightdb - ${currentDashboard.caption}`);
    }
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }

  logout() {
    this.authService.logout();
  }

  getProfileInfo() {
    // const fullUrl = this.apiEndpoints.profileInfoUrl;
    const fullUrl = this.apiEndpoints.profileInfoUrl;
    const reqBody = {};

    this.dashboardService.getDataForPostReq(fullUrl, reqBody).subscribe(
      response => {
        this.profile = response.data;
        // console.log(`Profile: ${this.profile.id}`);
        this.localStorageService.saveUserId(this.profile.id);
        if (this.profile.profilePicture) {
          this.profileUrl = response.data.profilePicture;
        } else {
          this.profileUrl = this.avterMaleImgUrl;
        }
      },
      error => console.log(error)
    );
  }

  openAboutDialog() {
    const dialogRef = this.dialog.open(AboutDialogComponent, {

      width: '550px',
      height: '250',
      data: { 'name': 'value' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.nextButtonClick();
      }
      console.log('The dialog was closed');
    });
  }


  /*sanitizeUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.profile.profilePicture);
  }*/
}
