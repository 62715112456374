<div class="container" style="padding-top: 15px; padding-bottom: 15px;">
    <div style="float: right;">
        <button [mat-dialog-close]="true">
            <i class="fa fa-times"></i>
        </button>
    </div>
    <br />
    <div class="row qr-dialog-container" style="padding-top: 20px;">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" id="instructionDiv">
            <div class="row">
                <div class="col-md-12">
                    <p class="c-login-custom-title">To use Insightdb on your computer:</p>
                    <ol style="padding-left: 20px;">
                        <li class="c-login-custom-p"><span>Open Insightdb on your phone</span></li>
                        <li class="c-login-custom-p"><span>Tap <strong>Menu</strong> <img
                                    src="assets/img/brand/icon_menu.png" alt="Image" class="img-fluid"
                                    style="height: 20px; padding-left: 4px; padding-right: 4px;" />and select
                                <strong>Web
                                    Login</strong></span></li>
                        <li class="c-login-custom-p">Point your phone to this screen to capture the code</li>
                    </ol>
                </div>
                <div class="col-md-12">
                    <video style="display: block;width: 100%;height: auto;" controls
                        poster="assets/img/brand/Thumbnail2.jpg">
                        <source src="assets/img/brand/insightdb.mp4" type="video/mp4">
                    </video>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" id="qrCodeDiv">
            <div class="row">
                <div class="col-md-12 c-login-qr flex-center-panel">
                    <qr-code [value]="qrdata" [size]="240" [padding]="0" *ngIf="qrdata !==''"></qr-code>
                </div>
                <div class="col-md-12" style="display: flex;align-items: center;justify-content: center;">
                    <mat-spinner *ngIf="qrdata ===''"></mat-spinner>
                </div>
            </div>
        </div>
    </div>
    <div class="row">

    </div>
</div>