<form class="example-form" [formGroup]="myForm" #f="ngForm">
    <div class="row flex-column-center-panel" style="width: 100%;margin: 0px;">
        <div class="col-md-12 text-center">
            <h5>Login With Email</h5>
        </div>
        <div class="col-md-12" style="margin-top: 16px;">
            <mat-form-field style="width: 100%;" appearance="outline">
                <mat-label>{{ " Email Address ​" }} </mat-label>
                <input matInput id="emailAddress" name="emailAddress" formControlName="emailAddress" type="text"
                    (keyup)="checkEmailAddressValidity($event.target.value)" [required]="true" />
                <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
            </mat-form-field>
        </div>
        <div class="col-md-12 text-left" *ngIf="showError">
            <p style="margin-bottom: 0px; margin-top: -20px; color: red; font-size: xx-small;">Your email address is not
                registered, please contact the system admin</p>
        </div>
    </div>
    <!-- align="end" -->
    <div mat-dialog-actions style="padding-left: 15px; padding-right: 15px;">
        <!-- <button mat-raised-button class="btn-danger" (click)="cancel()">
                {{ " বন্ধ​ করুন" }}
            </button> -->

        <button mat-raised-button class="btn-success" [disabled]="f.invalid || f.pristine || invalidEmail || isLoading"
            (click)="sendEmailExistRequest()" style="width: 100%;">
            <!-- <mat-icon *appSpinOn="isLoading">save</mat-icon> -->
            <span *appSpinOn="isLoading"></span>
            {{ " Send OTP " }}
        </button>
    </div>
</form>