export class Profile {
  userFullName: string;
  userEmail: string;
  mobile: string;
  gender: string;
  emailVerified: string;
  mobileVerified: string;
  nidVerified: string;
  dob: string;
  oauthId: string;
  profilePicture: string;
  code1: string;
  code2: string;
  enableTv: number;
  isLogged: number;
  webQrCode: string;
  id: string;
  webUserSession: any;
}
