<!-- <div class="row">
    <div class="col-8">{{data}}</div>
    <div class="col-4">
        <button (click)="sbRef.dismiss()">
            Dismiss
        </button>
    </div>
</div> -->

<div class="container">
    <div class="row flex-center-panel">
        <div class="col-5" style="padding: 0px;">
            <span style="color: white;">Would you like to view this in the app</span>
        </div>
        <div class="col-3" style="padding: 0px;">
            <img src="assets/img/brand/small_play_store.png"
                style="margin-left: auto; margin-right: auto; display: block;" alt="Get it on Google Play" (click)="this.util.openAndroidAppDownloadPage()">
        </div>
        <div class="col-3" style="padding: 0px;">
            <img src="assets/img/brand/small_ios_store.png" alt="Download on the App Store" (click)="this.util.openIosAppDownloadPage()">
        </div>
        <div class="col-1" style="padding: 0px;">
            <button (click)="sbRef.dismiss()">
                <i class="fa fa-times"></i>
            </button>
        </div>
    </div>
</div>