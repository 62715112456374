import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/index';
import {OssPidRequest} from '../../model/oss-pid-request';
import {DashboardViewResponseData} from '../../modules/dashboard/model/dashboard-view-response-data';
import {OssHelperService} from '../../utils/oss-helper.service';
import {ApiEndpoints} from '../../api-endpoints';
import { map } from 'rxjs/operators';

const insightDashboardServiceEndPoint = 'dashboard-obj/get-data';
const insightDashboardServiceEndPointError = 'dashboard-obj/info';

const serviceInfoUrl = 'common/version';

@Injectable({
  providedIn: 'root'
})
export class RequestUtilService {
  private apiEndpoints: ApiEndpoints = new ApiEndpoints();

  constructor(private http: HttpClient,private ossHelperService: OssHelperService) {}

  enableDevice(url: string, rb: Object): Observable<any> {
    return this.http.post(url, rb);
  }

  getChartResponseData(url: string, rb: DashboardViewResponseData): Observable<any> {
    const componentWiseRequest = this.ossHelperService.buildRequestBodyForComponentWiseData(rb);
    return this.http.post(url, componentWiseRequest);
    /*
    Initially only dashboard obj sent with req body.
    After changing api, 
    common req body with dashboard id and filter key sent for component wies request.
    */
    // return this.http.post(url, rb);
  }

  getChartData(dashBoardObj: any): Observable<any> {
    let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.dashboardServiceUrl + insightDashboardServiceEndPoint;
    const data = this.ossHelperService.buildRequestBodyForChartData(dashBoardObj);
    return this.http.post(fullUrl, data);
  }

  getChartDataForInside(dbObjId: any): Observable<any> {
    let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.authServiceUrl + insightDashboardServiceEndPointError;
    const data = this.ossHelperService.buildRequestBodyForChartDataInside(dbObjId);
    return this.http.post(fullUrl, data);
  }

  getChartInsideData(dashBoardObj: any): Observable<any> {
    let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.dashboardServiceUrl + insightDashboardServiceEndPoint;
    const data = this.ossHelperService.buildRequestBodyForChartInsideData(dashBoardObj);
    return this.http.post(fullUrl, data);
  }

  getChartInsideDataTest(dashBoardObj: any): Observable<any> {
    let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.dashboardServiceUrl + insightDashboardServiceEndPoint;
    const data = this.ossHelperService.buildRequestBodyForChartInsideData(dashBoardObj);
    return this.http.post(fullUrl, data).pipe(
      map(value => value)
    );
  }


  getDashboardServiceInfo(){
    let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.dashboardServiceUrl + serviceInfoUrl;
    return this.http.get(fullUrl).pipe(
      map(value => value)
    );
  }

  getUtilityServiceInfo(){
    let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.utilityServiceUrl + serviceInfoUrl;
    return this.http.get(fullUrl).pipe(
      map(value => value)
    );
  }

  getCoreServiceInfo(){
    let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.authServiceUrl + serviceInfoUrl;
    return this.http.get(fullUrl).pipe(
      map(value => value)
    );
  }

  getNotificationServiceInfo(){
    let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.notificationServiceUrl + serviceInfoUrl;
    return this.http.get(fullUrl).pipe(
      map(value => value)
    );
  }
}
