import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppBreadcrumbModule, AppFooterModule, AppHeaderModule } from '@coreui/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './core/login/login.component';
import { DefaultLayoutComponent } from './core/default-layout/default-layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule, ButtonsModule, TabsModule } from 'ngx-bootstrap';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { OssInsightComponent } from './core/oss-insight/oss-insight.component';
import { InactiveUserComponent } from './core/inactive-user/inactive-user.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import {
  MAT_DIALOG_DATA,
  MatButtonModule,
  MatCardModule,
  MatDialogModule,
  MatDialogRef,
  MatTooltipModule,
  MatDividerModule,
  MatProgressSpinnerModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatProgressSpinner
} from '@angular/material';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AuthInterceptor } from "./core/interceptors/auth-interceptor";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { QRCodeModule } from 'angular2-qrcode';
import { WebQrLoginDialogComponent } from './core/web-qr-login-dialog/web-qr-login-dialog.component';
import { SuggestionSnackbarComponent } from './modules/shared/suggestion-snackbar/suggestion-snackbar.coponent';
import { AboutDialogComponent } from './modules/admin-module/dialogBox/about-dialog/about-dialog.component';
import { EmailInputDialogComponent } from './core/email-input-dialog/email-input-dialog.component';
import { SpinOnDirective } from './core/shared/custom-directive/spin-on.directive';
import { OtpInputDialogComponent } from './core/otp-input-dialog/otp-input-dialog.component';
import { EmailLoginDialogComponent } from './core/email-login-dialog/email-login-dialog.component';
const APP_CONTAINERS = [
  DefaultLayoutComponent
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DefaultLayoutComponent,
    OssInsightComponent,
    InactiveUserComponent,
    ...APP_CONTAINERS,
    WebQrLoginDialogComponent,
    SuggestionSnackbarComponent,
    AboutDialogComponent,
    EmailInputDialogComponent,
    SpinOnDirective,
    OtpInputDialogComponent,
    EmailLoginDialogComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SlimLoadingBarModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    TabsModule.forRoot(),
    ToastrModule.forRoot(),
    MatDialogModule,
    MatButtonModule,
    MatCardModule,
    MatTooltipModule,
    AngularFontAwesomeModule,
    MatSnackBarModule,
    QRCodeModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  entryComponents: [
    WebQrLoginDialogComponent,
    SuggestionSnackbarComponent,
    AboutDialogComponent,
    EmailInputDialogComponent,
    MatProgressSpinner,
    OtpInputDialogComponent,
    EmailLoginDialogComponent,
  ],
  providers: [
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    Title,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
