import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { ApiEndpoints } from 'src/app/api-endpoints';
import { ApiObj } from '../api-obj/model/api-obj';
import { ClientObj } from '../client-list/model/client-obj';

const client = 'client';
const api = 'api';
const apiBank = 'bank-info';
const apiSearch = 'api/search';
const clientSearch = 'client/search';
const apiBankSearch = 'bank-info/search';
const jwtToken = 'token';
const bankDelete = 'api-bank';
const bankList = 'api-bank/bank-list-by-api';
const apiList = 'api-bank/api-list-by-bank/';
const clientList = 'client-banks/client-list-by-bank/';
const apiListForAddInBank = 'api/list-for-add-by-bank-oid/';
const apiBankListForAddInApi = 'bank-info/list-for-add-by-api-oid/';
const clientListForAddInBank = 'client/list-for-add-by-bank-oid/';
const apiAdd = 'api-bank';
const clientAdd = 'client-banks';
const clientDelete = 'client-banks';
const clientBalance = 'client-balance';
const apiVerify = 'api/verify';
const api_bank_list_by_client_id = 'client-banks/bank-list-by-client/';
const search = '/search/';
const apiBankListForAddInClient = 'bank-info/list-for-add-by-client-oid/';
@Injectable({
  providedIn: 'root'
})
export class ApiBankHelperService {
  private apiEndpoints: ApiEndpoints = new ApiEndpoints();
  constructor(private http: HttpClient) { }

  getJWtToken(): Observable<any> {
    let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.authServiceUrl + jwtToken;
    // dami url
    // let url = "http://192.168.30.83:5082/token";
    return this.http.get(url, {}).pipe(
        map(value => value)
    );
  }

  // Api

  // Api-list
  getApiObjTableData(page: number, size: number): Observable<any> {
    let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + api;
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.http.get<any>(fullUrl).pipe(
      map(value => value)
      );
  }

  // Api table Data Filter
  getAPITableDataForSearch(page: number, size: number, filterValue:any): Observable<any> {
    let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiSearch + '/' + filterValue;
    // let url =  'http://192.168.30.83:8701/api' + '/' + search + '/' + filterValue;
    // const headers = { 'Authorization': 'Bearer ' + jwtToken};
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.http.get<any>(fullUrl).pipe(
      map(value => value)
    );
  }


  getAPIByOid(oid:string): Observable<any> {
    let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + api + '/' + oid;
    return this.http.get<any>(url).pipe(
      map(value => value)
      );
  }
  getClientByOid(oid:string): Observable<any> {
    let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + client + '/' + oid;
    return this.http.get<any>(url).pipe(
      map(value => value)
      );
  }

  getApiBankByOid(oid:string): Observable<any> {
    let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiBank + '/' + oid;
    return this.http.get<any>(url).pipe(
      map(value => value)
      );
  }

  getClientInfoByOid(oid:string): Observable<any> {
    let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl  + client + '/info/' + oid;
    return this.http.get<any>(url).pipe(
      map(value => value)
      );
  }

  getClientTableDataForSearch(page: number, size: number, filterValue:any): Observable<any> {
    let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + clientSearch + '/' + filterValue;
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.http.get<any>(fullUrl).pipe(
      map(value => value)
    );
  }

  getAPIBankTableDataForSearch(page: number, size: number, filterValue:any): Observable<any> {
    let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiBankSearch + '/' + filterValue;
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.http.get<any>(fullUrl).pipe(
      map(value => value)
    );
  }

  getClientObjTableData(page: number, size: number): Observable<any> {
    let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + client;
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.http.get<any>(fullUrl).pipe(
      map(value => value)
      );
  }

  getApiBankObjTableData(page: number, size: number): Observable<any> {
    let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiBank;
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.http.get<any>(fullUrl).pipe(
      map(value => value)
      );
  }

  getApiBankTableDatabyApiid(page: number, size: number,oid:string): Observable<any> {
    let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl  + bankList + '/' + oid;
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.http.get<any>(fullUrl).pipe(
      map(value => value)
      );
  }

  getAPIBankListByClientId(page: number, size: number,clientOid:string): Observable<any> {
    let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl  + api_bank_list_by_client_id  + clientOid;
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.http.get<any>(fullUrl).pipe(
      map(value => value)
      );
  }


  getApiListForDisplayUsingBankOid(page: number, size: number,oid:string): Observable<any> {
    let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl  + apiList  +oid;
    const fullUrl = `${url}?page=${page}&size=${size}`;

    return this.http.get<any>(fullUrl).pipe(
      map(value => value)
      );
  }

  getClientListForDisplayUsingBankOid(page: number, size: number,oid:string): Observable<any> {
    let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl  + clientList  +oid;
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.http.get<any>(fullUrl).pipe(
      map(value => value)
      );
  }

  getAPIListForAddUsingBankOid(page: number, size: number,oid:string): Observable<any> {
    let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl  + apiListForAddInBank + oid;
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.http.get<any>(fullUrl).pipe(
      map(value => value)
      );
  }

  getAPIBankListForAddUsingAPIOid(page: number, size: number,oid:string): Observable<any> {
    let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl  + apiBankListForAddInApi + oid;
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.http.get<any>(fullUrl).pipe(
      map(value => value)
      );
  }

  getAPIBankListForAddUsingClientOid(page: number, size: number,oid:string): Observable<any> {
    let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl  + apiBankListForAddInClient + oid;
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.http.get<any>(fullUrl).pipe(
      map(value => value)
      );
  }

  getClientForAddUsingBankOid(page: number, size: number,oid:string): Observable<any> {
    let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl  + clientListForAddInBank + oid;
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.http.get<any>(fullUrl).pipe(
      map(value => value)
      );
  }

  createApiObj(apiObj: ApiObj): Observable<any> {
    let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + api;
    apiObj.oid = null;
    return this.http.post<any>(fullUrl, apiObj).pipe(
        map(value => value)
    );
  }

  createClientObj(apiObj: ClientObj): Observable<any> {
    let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + client;
    apiObj.oid = null;
    return this.http.post<any>(fullUrl, apiObj).pipe(
        map(value => value)
    );
  }

  addBalanceObj(Obj:any): Observable<any> {
    let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + clientBalance;
    Obj.oid = null;
    return this.http.post<any>(fullUrl, Obj).pipe(
        map(value => value)
    );
  }

  createApiBankObj(apiObj: any): Observable<any> {
    let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiBank;
    apiObj.oid = null;
    return this.http.post<any>(fullUrl, apiObj).pipe(
        map(value => value)
    );
  }

  addAPIForApiBank(apiObj: any): Observable<any> {
    let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiAdd;
    apiObj.oid = null;
    return this.http.post<any>(fullUrl, apiObj).pipe(
        map(value => value)
    );
  }



  addClientForApiBank(apiObj: any): Observable<any> {
    // let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + clientAdd;
    let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + clientAdd;
    apiObj.oid = null;
    return this.http.post<any>(fullUrl, apiObj).pipe(
        map(value => value)
    );
  }

  updateApiObj(oid:string,apiObj: ApiObj): Observable<any> {
    let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + api + '/' + oid;
    apiObj.oid = oid;
    return this.http.put<ApiObj>(fullUrl, apiObj).pipe(
        map(value => value)
    );
  }

  updateClientObj(oid:string,apiObj: ClientObj): Observable<any> {
    let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + client + '/' + oid;
    apiObj.oid = oid;
    return this.http.put<ApiObj>(fullUrl, apiObj).pipe(
        map(value => value)
    );
  }

  updateAPIBankObj(oid:string,apiObj: any): Observable<any> {
    let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiBank + '/' + oid;
    apiObj.oid = oid;
    return this.http.put<ApiObj>(fullUrl, apiObj).pipe(
        map(value => value)
    );
  }

  removePresentationItemForAPIBank(oid:string): Observable<any> {
    let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + bankDelete + '/' + oid;
    return this.http.delete<any>(fullUrl).pipe(
        map(value => value)
    );
  }

  removePresentationItemForAPI(oid:string): Observable<any> {
    let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + bankDelete + '/' + oid;
    return this.http.delete<any>(fullUrl).pipe(
        map(value => value)
    );
  }

  removePresentationItemForClient(oid:string): Observable<any> {
    let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + clientDelete + '/' + oid;
    return this.http.delete<any>(fullUrl).pipe(
        map(value => value)
    );
  }

  verifyDataSourceUsingQueryParams(obj: any, paramsObj:any): Observable<any> {
    let Params= new HttpParams();
    for (const [key, value] of Object.entries(paramsObj)) {
      Params= Params.append(`${key}`, `${value}`);
    }

    let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiVerify;
    return this.http.post<any>(fullUrl, obj, {params: Params}).pipe(
        map(value => value)
    );
  }


  //search-apis

  apiGetAPIBankDataForSearch(page: number, size: number, oid:string, filterValue:any): Observable<any> {
    let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl  + apiBankListForAddInApi + oid + search + filterValue;
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.http.get<any>(fullUrl).pipe(
      map(value => value)
    );
  }


  clientGetAPIBankDataForSearch(page: number, size: number, oid:string, filterValue:any): Observable<any> {
    let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl  + apiBankListForAddInClient + oid + search + filterValue;
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.http.get<any>(fullUrl).pipe(
      map(value => value)
    );
  }


  apiBankgetClientDataForSearch(page: number, size: number,oid:string , filterValue:any): Observable<any> {
    let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl  + clientListForAddInBank + oid + search + filterValue;
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.http.get<any>(fullUrl).pipe(
      map(value => value)
      );
  }

  apiBankgetAPIForSearch(page: number, size: number,oid:string, filterValue:any): Observable<any> {
    let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl  + apiListForAddInBank + oid + search + filterValue;
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.http.get<any>(fullUrl).pipe(
      map(value => value)
      );
  }

}
