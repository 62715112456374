export const REQUEST_TYPE = 'APP_LOG_IN';
export const VERSION = '2.18.4'; // TODO
export const OBJ_LIST = 'list';
export const OBJ_NEW = 'new';
export const OBJ_EDIT = 'edit';
export const OBJ_VERIFY = 'verify';
export const TITLE_SUCCESS = 'Success!!';
export const TITLE_ERROR = 'Error!!';
export const USER = 'USER';
export const USER_TYPE = 'USER_TYPE';
export let SHOW_SETTINGS = false;
