import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { VERSION } from '../../../../core/constants/oss-insight-constants';
import { RequestUtilService } from 'src/app/core/shared/request-util.service';

@Component({
  selector: 'app-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.css']
})
export class AboutDialogComponent implements OnInit {

  version = VERSION;
  dashboardServiceName = '';
  dashboardServiceVersion: string = '';

  utiLityServicename = '';
  utilityServiceVersion: string = '';

  coreServiceName = '';
  coreServiceVersion: string = '';

  notificationServiceName = '';
  notificationServiceVersion: string = '';


  constructor(private dialog: MatDialog,
    public dialogRef: MatDialogRef<AboutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private requestUtil: RequestUtilService,

  ) { }

  ngOnInit() {
    this.getDashboardServiceInfo();
    this.getUtilityServiceInfo();
    this.getCoreServiceInfo();
    this.getNotificationServiceInfo();
  }

  getDashboardServiceInfo() {
    this.requestUtil.getDashboardServiceInfo().subscribe(
      res => {
        // console.log(res);
        this.dashboardServiceName = res['data']['serviceName'];
        this.dashboardServiceVersion = res['data']['version']
      }
    );
  }

  getUtilityServiceInfo() {
    this.requestUtil.getUtilityServiceInfo().subscribe(
      res => {
        console.log(res);
        this.utiLityServicename = res['data']['serviceName'];
        this.utilityServiceVersion = res['data']['version']
      }
    );
  }

  getCoreServiceInfo() {
    this.requestUtil.getCoreServiceInfo().subscribe(
      res => {
        console.log(res);
        this.coreServiceName = res['data']['serviceName'];
        this.coreServiceVersion = res['data']['version']
      }
    );
  }

  getNotificationServiceInfo() {
    this.requestUtil.getNotificationServiceInfo().subscribe(
      res => {
        console.log(res);
        this.notificationServiceName = res['data']['serviceName'];
        this.notificationServiceVersion = res['data']['version']
      }
    );
  }

}
