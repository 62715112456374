<div class="container" style="padding-top: 15px; padding-bottom: 15px;">
    <div style="float: right;">
        <button [mat-dialog-close]="true">
            <i class="fa fa-times"></i>
        </button>
    </div>
    <br />

    <email-input-dialog (emailAddressEmitter)="receiveValidEmailAddress($event)" *ngIf="!emailAddress"></email-input-dialog>
    <otp-input-dialog [emailAddress]="emailAddress" *ngIf="emailAddress"></otp-input-dialog>
</div>