import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/index';
import {NavigationStart, Router} from '@angular/router';
import {AuthService} from "../login/auth.service";

@Component({
  templateUrl: 'inactive-user.component.html'
})
export class InactiveUserComponent implements OnInit, AfterViewChecked {
  subscription: Subscription;
  ngAfterViewChecked(): void {
  }

  constructor(private router: Router,
              private authService: AuthService) {}
  ngOnInit() {
    this.subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
      }
    });
  }

  logout() {
    this.authService.logout();
  }
}
