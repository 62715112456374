<form class="example-form" [formGroup]="myForm" #f="ngForm">
    <div class="row flex-column-center-panel" style="width: 100%;margin: 0px;">
        <div class="col-md-12 text-center">
            <h5>Verification</h5>
        </div>
        <div class="col-md-12 text-center"><span style="font-size: small;">OTP has been sent to your email
                address</span></div>
        <div class="col-md-12 text-center"><span style="font-size: small;"><b>{{emailAddress}}</b></span></div>
        <div class="col-md-12" style="margin-top: 16px;">
            <mat-form-field style="width: 100%;" appearance="outline">
                <mat-label>{{ " OTP Code ​" }} </mat-label>
                <!-- never set this to number because OTP code may have 0 at begining example: 026357-->
                <input matInput id="otpCode" name="otpCode" formControlName="otpCode" type="text" [required]="true" />
                <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
            </mat-form-field>
        </div>
        <div class="col-md-12 text-left" *ngIf="showError">
            <p style="margin-bottom: 0px; margin-top: -20px; color: red; font-size: xx-small;">Invalid OTP code, please
                check you email address</p>
        </div>
    </div>
    <!-- align="end" -->
    <div mat-dialog-actions style="padding-left: 15px; padding-right: 15px;">
        <button mat-raised-button class="btn-success" [disabled]="f.invalid || f.pristine || isLoading"
            (click)="sendEmailExistRequest()" style="width: 100%;">
            <span *appSpinOn="isLoading"></span>
            {{ " Verify & Proceed " }}
        </button>
        <div class="text-center" style="width: 100%; padding-top: 8px;">
            <span>Didn't receive the OTP? {{ remainingTimeText }}</span>
            <button mat-button color="primary" *ngIf="showOTPBtn" (click)="resendOTPCode()">Resend OTP</button>
        </div>
    </div>
</form>