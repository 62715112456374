import { Component, Inject, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent, MatPaginator } from '@angular/material';

@Component({
  selector: 'app-error-alert-dialog',
  templateUrl: './error-alert-dialog.component.html',
  styleUrls: ['./error-alert-dialog.component.css']
})
export class ErrorAlertDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ErrorAlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer) { }


  customMessage: string;
  testData: string;
  isDataAvailable: boolean = false;
  dataSize = 10;
  public pageSizeOptions = [5, 10, 20];
  pageEvent: PageEvent;
  paginatorLength: number;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource();
  displayedColumns: string[];


  ngOnInit() {
    this.customMessage = this.data.errorDetails;
  }

  onNoClick(): void {
    this.dialogRef.close(this.data);
  }

  setDataSourceData(data: any) {
    this.dataSource.data = data;
  }

  public sanitizeValue(data: string) {
    this.testData = this.sanitizer.sanitize(SecurityContext.HTML, data);
  }

  onDetails() {
    this.data.details = this.customMessage;
  }

  setDisplayedColumnValue(data: any) {
    let keys = Object.keys(data[0]);
    let nextObjIdPosition = -1;

    $.each(keys, function (i, it) {
      // if (it === 'next_obj_id') {
      //   nextObjIdPosition = i;
      // }
    });

    let columns_obj = [];
    $.each(keys, function (i, it) {
      if (nextObjIdPosition != i) {
        columns_obj.push(it);
      }
    });
    this.displayedColumns = columns_obj;
  }
}
