import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { of, merge, NEVER } from 'rxjs';
import { mapTo, delay, map, switchMap } from 'rxjs/operators';
import { UtilService } from 'src/app/utils/util.service';
import { EmailLoginService } from '../email-login-dialog/email-login.service';
import { LocalStorageService } from '../shared/service/local-storage-service';

@Component({
    selector: 'email-input-dialog',
    templateUrl: './email-input-dialog.component.html',
    styleUrls: ['./email-input-dialog.component.css']
})

export class EmailInputDialogComponent implements OnInit {

    @Output() emailAddressEmitter = new EventEmitter<any>();;

    myForm: FormGroup;
    invalidEmail: boolean = true;
    isLoading: boolean = false;
    showError: boolean = false;
    emailAddress: string = '';
    errorMessage = '';
    contactSystemAdmin = 'Please contact system admin!!!';

    constructor(public fb: FormBuilder,
        public util: UtilService,
        private service: EmailLoginService,
        private localStorageService: LocalStorageService) { }

    ngOnInit() {
        this.initReactiveForm();
    }

    initReactiveForm() {
        this.myForm = this.fb.group({
            emailAddress: ['', [Validators.required]],
        });
    }

    checkEmailAddressValidity(emailAddress) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.invalidEmail = !re.test(emailAddress);
    }

    sendEmailExistRequest() {
        const emailAddress = this.myForm.controls['emailAddress'].value;
        this.isLoading = true;

        this.service.executeEmailExistReq(emailAddress)
            .pipe(map(res => this.parserUserInfoRes(res, emailAddress)))
            .pipe(switchMap(shouldRequest => shouldRequest ? this.service.sendOTP(this.emailAddress) : NEVER))
            .subscribe(res => this.parseOTPRes(res)
                , error => {
                    // console.log(error);
                    this.isLoading = false;
                    this.util.showSnackBarMessage(this.contactSystemAdmin, this.util.TYPE_MESSAGE.ERROR_TYPE);
                });
    }

    parserUserInfoRes(response, emailAddress): boolean {
        if (response && response.status === 200 && response.data && response.data.email === emailAddress) {
            this.localStorageService.saveUserInfoForEmailLogin(response.data);
            this.emailAddress = emailAddress;
            return true;
        }
        this.util.showSnackBarMessage(`Your email address is not registered. ${this.contactSystemAdmin}`
            , this.util.TYPE_MESSAGE.ERROR_TYPE);
        return false;
    }

    parseOTPRes(response) {
        this.isLoading = false;
        if (response && response.status === 200 && response.data && response.data.id) {
            this.emailAddressEmitter.emit(this.emailAddress);
            return;
        }
        this.util.showSnackBarMessage(`Failed to send OTP code to your email address. ${this.contactSystemAdmin}`
            , this.util.TYPE_MESSAGE.ERROR_TYPE);
    }

}