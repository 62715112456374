import { Routes } from '@angular/router';
import { LoginComponent } from './core/login/login.component';
import { DefaultLayoutComponent } from './core/default-layout/default-layout.component';
import { AuthGurd } from './core/login/auth.gurd';
import { OssInsightComponent } from './core/oss-insight/oss-insight.component';
import { InactiveUserComponent } from './core/inactive-user/inactive-user.component';
const ɵ0 = {
    title: 'Login Page'
}, ɵ1 = {
    title: 'Home'
}, ɵ2 = () => import("./modules/dashboard/dashboard.module.ngfactory").then(m => m.DashboardModuleNgFactory), ɵ3 = () => import("./modules/preview-report/preview-report.module.ngfactory").then(m => m.PreviewReportModuleNgFactory);
export const routes = [
    {
        path: 'login',
        component: LoginComponent,
        data: ɵ0
    },
    {
        path: 'oss-insight/osspid-callback',
        component: OssInsightComponent
    },
    {
        path: 'inactive-user',
        component: InactiveUserComponent
    },
    // {
    //   path: 'tv-dashboard',
    //   component: TvdashboardComponent
    // },
    // {
    //   path: 'preview-report',
    //   component: ReportExplorerComponent,
    //   loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule),
    //   canActivate: [AuthGurd],
    // },
    /*{
      path: 'tv-chart',
      component: TvChartComponent
    },*/
    {
        path: '',
        component: DefaultLayoutComponent,
        canActivate: [AuthGurd],
        data: ɵ1,
        children: [
            {
                path: 'dashboard',
                loadChildren: ɵ2
            }
            // ,
            // {
            //   path: 'settings',
            //   loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
            // }
        ]
    },
    {
        path: 'preview-report',
        loadChildren: ɵ3,
        canActivate: [AuthGurd]
    },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3 };
