import { BehaviorSubject } from 'rxjs/index';
import * as i0 from "@angular/core";
export class DashboardObjRefreshDataService {
    constructor() {
        this.dataSource = new BehaviorSubject(null);
        this.data = this.dataSource.asObservable();
    }
    updatedData(data) {
        this.dataSource.next(data);
    }
}
DashboardObjRefreshDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DashboardObjRefreshDataService_Factory() { return new DashboardObjRefreshDataService(); }, token: DashboardObjRefreshDataService, providedIn: "root" });
