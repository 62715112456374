/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./oss-insight.component";
import * as i2 from "@angular/router";
import * as i3 from "../../utils/util.service";
import * as i4 from "../../utils/oss.service";
import * as i5 from "../login/auth.service";
import * as i6 from "@angular/material/snack-bar";
import * as i7 from "../login/storage.service";
import * as i8 from "../../utils/oss-helper.service";
import * as i9 from "../shared/service/local-storage-service";
var styles_OssInsightComponent = [];
var RenderType_OssInsightComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OssInsightComponent, data: {} });
export { RenderType_OssInsightComponent as RenderType_OssInsightComponent };
export function View_OssInsightComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "overlay"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["class", "spinner"], ["src", "assets/img/brand/loader_b.gif"], ["style", "height: 200px; width: 200px"]], null, null, null, null, null))], null, null); }
export function View_OssInsightComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_OssInsightComponent_0, RenderType_OssInsightComponent)), i0.ɵdid(1, 114688, null, 0, i1.OssInsightComponent, [i2.ActivatedRoute, i3.UtilService, i4.OssService, i2.Router, i5.AuthService, i6.MatSnackBar, i7.StorageService, i8.OssHelperService, i9.LocalStorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OssInsightComponentNgFactory = i0.ɵccf("ng-component", i1.OssInsightComponent, View_OssInsightComponent_Host_0, {}, {}, []);
export { OssInsightComponentNgFactory as OssInsightComponentNgFactory };
