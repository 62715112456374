import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ApiEndpoints } from 'src/app/api-endpoints';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const client = 'client';
const api = 'api';
const apiBank = 'bank-info';
const apiSearch = 'api/search';
const clientSearch = 'client/search';
const apiBankSearch = 'bank-info/search';
const jwtToken = 'token';
const bankDelete = 'api-bank';
const bankList = 'api-bank/bank-list-by-api';
const apiList = 'api-bank/api-list-by-bank/';
const clientList = 'client-banks/client-list-by-bank/';
const apiListForAddInBank = 'api/list-for-add-by-bank-oid/';
const apiBankListForAddInApi = 'bank-info/list-for-add-by-api-oid/';
const clientListForAddInBank = 'client/list-for-add-by-bank-oid/';
const apiAdd = 'api-bank';
const clientAdd = 'client-banks';
const clientDelete = 'client-banks';
const clientBalance = 'client-balance';
const apiVerify = 'api/verify';
const api_bank_list_by_client_id = 'client-banks/bank-list-by-client/';
const search = '/search/';
const apiBankListForAddInClient = 'bank-info/list-for-add-by-client-oid/';
export class ApiBankHelperService {
    constructor(http) {
        this.http = http;
        this.apiEndpoints = new ApiEndpoints();
    }
    getJWtToken() {
        let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.authServiceUrl + jwtToken;
        // dami url
        // let url = "http://192.168.30.83:5082/token";
        return this.http.get(url, {}).pipe(map(value => value));
    }
    // Api
    // Api-list
    getApiObjTableData(page, size) {
        let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + api;
        const fullUrl = `${url}?page=${page}&size=${size}`;
        return this.http.get(fullUrl).pipe(map(value => value));
    }
    // Api table Data Filter
    getAPITableDataForSearch(page, size, filterValue) {
        let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiSearch + '/' + filterValue;
        // let url =  'http://192.168.30.83:8701/api' + '/' + search + '/' + filterValue;
        // const headers = { 'Authorization': 'Bearer ' + jwtToken};
        const fullUrl = `${url}?page=${page}&size=${size}`;
        return this.http.get(fullUrl).pipe(map(value => value));
    }
    getAPIByOid(oid) {
        let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + api + '/' + oid;
        return this.http.get(url).pipe(map(value => value));
    }
    getClientByOid(oid) {
        let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + client + '/' + oid;
        return this.http.get(url).pipe(map(value => value));
    }
    getApiBankByOid(oid) {
        let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiBank + '/' + oid;
        return this.http.get(url).pipe(map(value => value));
    }
    getClientInfoByOid(oid) {
        let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + client + '/info/' + oid;
        return this.http.get(url).pipe(map(value => value));
    }
    getClientTableDataForSearch(page, size, filterValue) {
        let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + clientSearch + '/' + filterValue;
        const fullUrl = `${url}?page=${page}&size=${size}`;
        return this.http.get(fullUrl).pipe(map(value => value));
    }
    getAPIBankTableDataForSearch(page, size, filterValue) {
        let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiBankSearch + '/' + filterValue;
        const fullUrl = `${url}?page=${page}&size=${size}`;
        return this.http.get(fullUrl).pipe(map(value => value));
    }
    getClientObjTableData(page, size) {
        let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + client;
        const fullUrl = `${url}?page=${page}&size=${size}`;
        return this.http.get(fullUrl).pipe(map(value => value));
    }
    getApiBankObjTableData(page, size) {
        let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiBank;
        const fullUrl = `${url}?page=${page}&size=${size}`;
        return this.http.get(fullUrl).pipe(map(value => value));
    }
    getApiBankTableDatabyApiid(page, size, oid) {
        let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + bankList + '/' + oid;
        const fullUrl = `${url}?page=${page}&size=${size}`;
        return this.http.get(fullUrl).pipe(map(value => value));
    }
    getAPIBankListByClientId(page, size, clientOid) {
        let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + api_bank_list_by_client_id + clientOid;
        const fullUrl = `${url}?page=${page}&size=${size}`;
        return this.http.get(fullUrl).pipe(map(value => value));
    }
    getApiListForDisplayUsingBankOid(page, size, oid) {
        let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiList + oid;
        const fullUrl = `${url}?page=${page}&size=${size}`;
        return this.http.get(fullUrl).pipe(map(value => value));
    }
    getClientListForDisplayUsingBankOid(page, size, oid) {
        let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + clientList + oid;
        const fullUrl = `${url}?page=${page}&size=${size}`;
        return this.http.get(fullUrl).pipe(map(value => value));
    }
    getAPIListForAddUsingBankOid(page, size, oid) {
        let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiListForAddInBank + oid;
        const fullUrl = `${url}?page=${page}&size=${size}`;
        return this.http.get(fullUrl).pipe(map(value => value));
    }
    getAPIBankListForAddUsingAPIOid(page, size, oid) {
        let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiBankListForAddInApi + oid;
        const fullUrl = `${url}?page=${page}&size=${size}`;
        return this.http.get(fullUrl).pipe(map(value => value));
    }
    getAPIBankListForAddUsingClientOid(page, size, oid) {
        let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiBankListForAddInClient + oid;
        const fullUrl = `${url}?page=${page}&size=${size}`;
        return this.http.get(fullUrl).pipe(map(value => value));
    }
    getClientForAddUsingBankOid(page, size, oid) {
        let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + clientListForAddInBank + oid;
        const fullUrl = `${url}?page=${page}&size=${size}`;
        return this.http.get(fullUrl).pipe(map(value => value));
    }
    createApiObj(apiObj) {
        let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + api;
        apiObj.oid = null;
        return this.http.post(fullUrl, apiObj).pipe(map(value => value));
    }
    createClientObj(apiObj) {
        let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + client;
        apiObj.oid = null;
        return this.http.post(fullUrl, apiObj).pipe(map(value => value));
    }
    addBalanceObj(Obj) {
        let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + clientBalance;
        Obj.oid = null;
        return this.http.post(fullUrl, Obj).pipe(map(value => value));
    }
    createApiBankObj(apiObj) {
        let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiBank;
        apiObj.oid = null;
        return this.http.post(fullUrl, apiObj).pipe(map(value => value));
    }
    addAPIForApiBank(apiObj) {
        let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiAdd;
        apiObj.oid = null;
        return this.http.post(fullUrl, apiObj).pipe(map(value => value));
    }
    addClientForApiBank(apiObj) {
        // let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + clientAdd;
        let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + clientAdd;
        apiObj.oid = null;
        return this.http.post(fullUrl, apiObj).pipe(map(value => value));
    }
    updateApiObj(oid, apiObj) {
        let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + api + '/' + oid;
        apiObj.oid = oid;
        return this.http.put(fullUrl, apiObj).pipe(map(value => value));
    }
    updateClientObj(oid, apiObj) {
        let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + client + '/' + oid;
        apiObj.oid = oid;
        return this.http.put(fullUrl, apiObj).pipe(map(value => value));
    }
    updateAPIBankObj(oid, apiObj) {
        let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiBank + '/' + oid;
        apiObj.oid = oid;
        return this.http.put(fullUrl, apiObj).pipe(map(value => value));
    }
    removePresentationItemForAPIBank(oid) {
        let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + bankDelete + '/' + oid;
        return this.http.delete(fullUrl).pipe(map(value => value));
    }
    removePresentationItemForAPI(oid) {
        let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + bankDelete + '/' + oid;
        return this.http.delete(fullUrl).pipe(map(value => value));
    }
    removePresentationItemForClient(oid) {
        let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + clientDelete + '/' + oid;
        return this.http.delete(fullUrl).pipe(map(value => value));
    }
    verifyDataSourceUsingQueryParams(obj, paramsObj) {
        let Params = new HttpParams();
        for (const [key, value] of Object.entries(paramsObj)) {
            Params = Params.append(`${key}`, `${value}`);
        }
        let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiVerify;
        return this.http.post(fullUrl, obj, { params: Params }).pipe(map(value => value));
    }
    //search-apis
    apiGetAPIBankDataForSearch(page, size, oid, filterValue) {
        let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiBankListForAddInApi + oid + search + filterValue;
        const fullUrl = `${url}?page=${page}&size=${size}`;
        return this.http.get(fullUrl).pipe(map(value => value));
    }
    clientGetAPIBankDataForSearch(page, size, oid, filterValue) {
        let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiBankListForAddInClient + oid + search + filterValue;
        const fullUrl = `${url}?page=${page}&size=${size}`;
        return this.http.get(fullUrl).pipe(map(value => value));
    }
    apiBankgetClientDataForSearch(page, size, oid, filterValue) {
        let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + clientListForAddInBank + oid + search + filterValue;
        const fullUrl = `${url}?page=${page}&size=${size}`;
        return this.http.get(fullUrl).pipe(map(value => value));
    }
    apiBankgetAPIForSearch(page, size, oid, filterValue) {
        let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.apiBankServiceUrl + apiListForAddInBank + oid + search + filterValue;
        const fullUrl = `${url}?page=${page}&size=${size}`;
        return this.http.get(fullUrl).pipe(map(value => value));
    }
}
ApiBankHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiBankHelperService_Factory() { return new ApiBankHelperService(i0.ɵɵinject(i1.HttpClient)); }, token: ApiBankHelperService, providedIn: "root" });
