import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../utils/util.service';
import { OssService } from '../../utils/oss.service';
import { AuthService } from '../login/auth.service';
import { RegisteredUrl } from '../../model/registered-url';
import { CodeStatus } from '../../model/code-status';
import { StorageService } from '../login/storage.service';
import { OssHelperService } from '../../utils/oss-helper.service';
import { LocalStorageService } from '../shared/service/local-storage-service';
import { DashboardObject } from '../../model/dashboard-object';
import { MatSnackBar } from '@angular/material/snack-bar';



@Component({
  template: `<div class="overlay">
  <img src="assets/img/brand/loader_b.gif" class="spinner" style="height: 200px; width: 200px">
</div>`
})
export class OssInsightComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private ossService: OssService,
    private router: Router,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    private storageService: StorageService,
    private ossHelperService: OssHelperService,
    private localStorageService: LocalStorageService) {
  }

  ngOnInit() {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    this.saveUserCredentials(queryParams);
    const ossPidRequest = this.ossHelperService.buildOssPidRequestLoginForCallBack();

    // const ossPidRequest = this.ossHelperService.buildOssPidRequestForCallBack();
    // const verificationReqObj = this.utilService.buildOssPidRequestBody(ossPidRequest);


    this.ossVerification(ossPidRequest);
  }

  ossVerification(verificationObject: Object) {
    this.ossService.ossVerification(verificationObject).subscribe(
      response => {
        this.redirectAfterOssVerification(response);
      },
      error => {
        console.log(error);
        if (error && error.status === 400) {
          this._snackBar.open("Please try after One minute", "", {
            duration: 2000
          });
          this.router.navigate(['/login']);
        }
      }
    );
  }

  /**
   * Right now same code is present in #web-qr-login-dialog.component
   * If any change needed change in that file too
   * It will be better to re-use it in both file...
   * @param response
   */
  redirectAfterOssVerification(response) {
    // console.log('***',response);
    if (response.data.isAdmin) {
      this.localStorageService.saveAdmin(response.data.isAdmin);
    } else {
      this.localStorageService.saveAdmin(false);
    }

    let dashboardMenuList = response.data.dashboardMenus;
    this.localStorageService.saveDashboardMenus(dashboardMenuList);

    let dashboardObjList = new Array<DashboardObject>();
    dashboardObjList = response.data.dashboards;
    this.localStorageService.saveDashboardObjs(dashboardObjList);

    this.localStorageService.saveCurrentDashboard(dashboardObjList[0]);

    if (response.status === 200 && response.data.status === true) {
      this.router.navigate(['/dashboard']);
    } else if (response.status === 200 && response.data.status === false) {
      this.router.navigate(['/inactive-user']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  saveUserCredentials(queryParams) {
    const oauthData = queryParams.oauth_data;
    const oauthToken = queryParams.oauth_token;
    this.authService.saveUserCredentials(oauthData, oauthToken);
  }
}
