export class ApiEndpoints {
  // ROOT_URL = 'https://covid.oss.net.bd/api'; // for Covid Live SERVER
  // ROOT_URL = 'https://doe.oss.net.bd/api'; // for DOE SERVER
  // ROOT_URL = 'https://insightdb.oss.net.bd/api'; // for INSIGHT_DB LIVE SERVER
  // ROOT_URL = 'https://k8s-insight.oss.net.bd/api';
  ROOT_URL = '/api'; // for RELATIVE PATH
  // ROOT_URL ='https://uat-insightdb.oss.net.bd/api';
  // ROOT_URL = 'https://uat-insightdb.oss.net.bd/gateway/api'; // for UAT-SERVER
  // ROOT_URL = 'http://127.0.0.1:8080/api'; // for DEV SERVER
  // ROOT_URL = 'https://apiba.oss.net.bd/api';
  // ROOT_URL = 'http://154.127.52.103:8080/api'; //DramApps
  // ROOT_URL = 'http://192.168.30.83:8080/api'; // for DEV SERVER
  // ROOT_URL = 'https://dashboardapi.nesco.gov.bd/api'; // for NESCO

  // For UAT FEEDBACK
  clientId = '151a1588-be11-47a5-8b35-334ac541d52e';
  accessToken = 'IndfCXdus5try2skpSM7rIU6iomsaRtr';

  // For LIVE FEEDBACK
  // clientId = 'b2a4f8db-516a-42ce-8d49-5876945b8711';
  // accessToken = 'kBCfCXai5ttg2skpSM7rIU6ioCEDH0NS';




  // Service Endpoint
  authServiceUrl = '/core-service/';
  utilityServiceUrl = '/utility/';
  dashboardServiceUrl = '/dashboard/';
  apiBankServiceUrl = '/api-bank/';
  notificationServiceUrl = '/notification-service/';

  ossLoginUrl = `${this.ROOT_URL + this.authServiceUrl}login/get_oss_pid_url`;
  ossCallbackUrl = `${this.ROOT_URL}/oss-insight/osspid-callback`;
  ossVerificationUrl = `${this.ROOT_URL + this.authServiceUrl}login/get_oss_pid_login`;
  ossLogOutUrl = `${this.ROOT_URL + this.authServiceUrl}login/get_oss_pid_logout`;
  ossThirdPartyLoginUrl = `${this.ROOT_URL}/api/oss-insight-third-party-auth-service/qpro-user-login`;
  dashboardDataUrl = `${this.ROOT_URL + this.dashboardServiceUrl}get-data`;

  dataSourceCreate = `${this.ROOT_URL}/api/oss-insight-admin-module-service/create_data_source_data`;
  dataSourceUpdate = `${this.ROOT_URL}/api/oss-insight-admin-module-service/update_data_source_data`;
  dataSourceTestConnection = `${this.ROOT_URL}/api/oss-insight-admin-module-service/test_db_connection`;

  connectivityUrl = `${this.ROOT_URL}/api/oss-insight-network-status-service/api/get_netwo  rk_status_master`;
  statusCheckUrl = `${this.ROOT_URL}/api/core-service/api/get_oss_verify_and_status`;
  profileInfoUrl = `${this.ROOT_URL}/core-service/user/get-user-profile`;
  enableDevice = `${this.ROOT_URL}/core-service/device/generate-code-and-enable-device`;
  disableDevice = `${this.ROOT_URL}/core-service/device/delete-code-and-disable-device`;
  qrCodeUrl = `${this.ROOT_URL}/core-service/device/get-qr-code-web`;
  androidLogoutFromWeb = `${this.ROOT_URL}/core-service/device/android-logout-from-web`;
  restAndroidLogoutFromWeb = `${this.ROOT_URL}/core-service/device/reset-android-user-login-status`;
  qrCodeForWebLoginUrl = `${this.ROOT_URL + this.authServiceUrl}device/get-qr-code-for-web-login`;
  qrCodeInfo = `${this.ROOT_URL + this.authServiceUrl}device/qr-code-info`;
  sendOTP = `${this.ROOT_URL + this.authServiceUrl}email-otp/send-otp`;
  emailLogin = `${this.ROOT_URL + this.authServiceUrl}email-otp/email-login`;
}
