import { Subject } from 'rxjs/index';
import { ApiEndpoints } from '../../../api-endpoints';
import { map } from 'rxjs/internal/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../core/shared/service/local-storage-service";
import * as i3 from "../../../core/shared/service/data-service";
const accessLogListUrl = 'access-log/get-list-by-user-id';
const dashboardGetListForUserByIdUrl = 'dashboard/get-list-for-user-by-id';
const accessLogLogoutUrl = 'access-log/logout';
export class DashboardService {
    constructor(httpClient, localStorageService, dataService) {
        this.httpClient = httpClient;
        this.localStorageService = localStorageService;
        this.dataService = dataService;
        this.apiEndPoint = new ApiEndpoints();
    }
    getDataForPostReq(fullUrl, ossPidRequest) {
        return this.httpClient.post(fullUrl, ossPidRequest);
    }
    getDataForDashboard(dashboardId, filterKey) {
        let url = this.apiEndPoint.ROOT_URL + this.apiEndPoint.dashboardServiceUrl + 'dashboard/get-data';
        let data = { id: dashboardId, filterKey: filterKey };
        return this.httpClient.post(url, data, { observe: 'response' });
        //  --> to get response header
    }
    /*
    checkDatabaseConnection(dataSource: DataSource){
      let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.utilityServiceUrl + checkDbConnectionUrl;
      return this.http.post<DbConnectionInfo>(fullUrl, dataSource);
  
      getUserTableData(page: number, size: number): Observable<any> {
      let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.authServiceUrl + userGetListUrl;
      // let url = 'http://192.168.152.82:8082/'+userGetListUrl;
      const fullUrl = `${url}?page=${page}&size=${size}`;
      return this.http.post(fullUrl, {}).pipe(
        map(value => value)
      );
    }
    }*/
    saveMaximizeData(userWiserDashboardView) {
        let fullUrl = this.apiEndPoint.ROOT_URL + this.apiEndPoint.authServiceUrl + 'uwdv/create';
        return this.httpClient.post(fullUrl, userWiserDashboardView).pipe(map(value => value));
    }
    getConnectivityData() {
        const observable = new Subject();
        this.httpClient.get(this.apiEndPoint.connectivityUrl).subscribe(response => {
            observable.next(response);
        }, error => {
            observable.next(error);
        });
        return observable;
    }
    getStaticData(fullUrl) {
        return this.httpClient.get(fullUrl);
    }
    saveDashboardObjAndRefreshDashboardData(dashboards) {
        let dashboardObjList = new Array();
        dashboardObjList = dashboards;
        this.localStorageService.saveDashboardObjs(dashboardObjList);
        this.dataService.updatedData(dashboardObjList);
    }
    getAccessLogList(userId, page, size) {
        let data = { id: userId };
        let url = this.apiEndPoint.ROOT_URL + this.apiEndPoint.authServiceUrl + accessLogListUrl;
        const fullUrl = `${url}?page=${page}&size=${size}`;
        return this.httpClient.post(fullUrl, data).pipe(map(value => value));
    }
    getDashboardObjAccessList(userId, page, size) {
        let data = { id: userId };
        let url = this.apiEndPoint.ROOT_URL + this.apiEndPoint.authServiceUrl + 'dashboard-obj/dashboard-obj-access-info';
        const fullUrl = `${url}?page=${page}&size=${size}`;
        return this.httpClient.post(fullUrl, data).pipe(map(value => value));
    }
    // getDashboardObjAccessList(userId: any,page:any,size:any) {
    //   const fullUrl1 = this.apiEndPoint.profileInfoUrl;
    //   const reqBody = {};
    //   this.httpClient.post(fullUrl1, reqBody).toPromise().then(res => {
    //     console.log('First Promise resolved ----- ', res);
    //     if (res['data']['content']['id']) {
    //       let data = {id: userId};
    //       let url = this.apiEndPoint.ROOT_URL + this.apiEndPoint.authServiceUrl + 'dashboard-obj/dashboard-obj-access-info';
    //       const fullUrl = `${url}?page=${page}&size=${size}`;
    //       return this.httpClient.post(fullUrl, data).pipe(
    //         map(value => value)
    //       );
    //       // this.httpClient.post(fullUrl, data).toPromise().then(data => {
    //       //   console.log('Second Promise resolved ----', data)
    //       // });
    //     }
    //   });
    // }
    getDashboardDataForUserById(page, size, profile) {
        let url = this.apiEndPoint.ROOT_URL + this.apiEndPoint.authServiceUrl + dashboardGetListForUserByIdUrl;
        const fullUrl = `${url}?page=${page}&size=${size}`;
        return this.httpClient.post(fullUrl, profile).pipe(map(value => value));
    }
    getAccessLogLogoutData(data) {
        let fullUrl = this.apiEndPoint.ROOT_URL + this.apiEndPoint.authServiceUrl + accessLogLogoutUrl;
        return this.httpClient.post(fullUrl, data).pipe(map(value => value));
    }
}
DashboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DashboardService_Factory() { return new DashboardService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i3.DashboardObjRefreshDataService)); }, token: DashboardService, providedIn: "root" });
