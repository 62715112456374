import {Injectable} from '@angular/core';
import {OssPidRequest} from '../model/oss-pid-request';
import {RequestData} from '../model/request-data';
import {UtilService} from './util.service';
import {LocalStorageService} from '../core/shared/service/local-storage-service';
import {REQUEST_TYPE, VERSION} from '../core/constants/oss-insight-constants';
import {CodeStatus} from '../model/code-status';
import {ParentChartData} from '../modules/dashboard/dashboard/all-chart/model/parent-chart-data';
import {DetailReqBody} from '../model/detail-req-body';
import {DetailReqData} from '../model/detail-req-data';
import {DashboardTableData} from '../modules/admin-module/dashboard-list/dashboard-table-data';
import {UserTypeListData} from '../modules/admin-module/user-type-list/user-type-list-data';
import {DashboardViewResponseData} from '../modules/dashboard/model/dashboard-view-response-data';
import { LoginInfoBean } from '../modules/dashboard/model/login-info-bean';


@Injectable({
  providedIn: 'root'
})
export class OssHelperService {
  constructor(private utilService: UtilService,
              private localStorageService: LocalStorageService) {
  }

  buildOssPidRequest(): OssPidRequest {
    const ossPidRequest = new OssPidRequest();
    ossPidRequest.requestData = new RequestData();
    ossPidRequest.requestData.oauth_data = this.localStorageService.getOauthData();
    ossPidRequest.requestData.oauth_token = this.localStorageService.getOauthToken();
    ossPidRequest.requestData.ipAddress = this.utilService.getIpAddress();
    ossPidRequest.requestData.userAgent = this.utilService.getBrowserUserAgent();
    const dashboardId = this.localStorageService.getCurrentDashboard().id;
    ossPidRequest.requestData.dashboardId = dashboardId ? dashboardId : '1';
    ossPidRequest.requestData.id = dashboardId ? dashboardId : '1';
    ossPidRequest.requestType = REQUEST_TYPE;
    ossPidRequest.version = VERSION;
    return ossPidRequest;
  }

  buildOssPidRequestForCallBack(): OssPidRequest {
    const ossPidRequest = new OssPidRequest();
    ossPidRequest.requestData = new RequestData();
    ossPidRequest.requestData.oauth_data = this.localStorageService.getOauthData();
    ossPidRequest.requestData.oauth_token = this.localStorageService.getOauthToken();
    ossPidRequest.requestData.ipAddress = this.utilService.getIpAddress();
    ossPidRequest.requestData.userAgent = this.utilService.getBrowserUserAgent();
    ossPidRequest.requestType = REQUEST_TYPE;
    ossPidRequest.version = VERSION;
    return ossPidRequest;
  }




  buildOssPidRequestLoginForCallBack(): LoginInfoBean {
    const ossPidRequest = new LoginInfoBean();
    ossPidRequest.oauth_data = this.localStorageService.getOauthData();
    ossPidRequest.oauth_token = this.localStorageService.getOauthToken();
    return ossPidRequest;
  }

  buildOssPidRequestLogout(): LoginInfoBean {
    const ossPidRequest = new LoginInfoBean();
    ossPidRequest.oauth_data = this.localStorageService.getOauthData();
    ossPidRequest.oauth_token = this.localStorageService.getOauthToken();
    return ossPidRequest;
  }






  buildRequestBodyForComponentWiseData(rb: DashboardViewResponseData):any{
    const reqBody = {
      osspidRequest: {
        requestType: REQUEST_TYPE,
        deviceId: this.utilService.getDeviceId(),
        clientId: this.utilService.getClientId(),
        secretKey: this.utilService.getSecretKey(),
        version: VERSION,
        requestData: {
          oauth_token: this.localStorageService.getOauthToken(),
          oauth_data: this.localStorageService.getOauthData(),
          id: rb.id,
          filter_key: this.localStorageService.getDashboardFilterKey(),
        }
      }
    };
    return reqBody;
  }

  buildRequestBodyForChartData(rb: any):any{
    const data = { dbObjId : rb.dbObjId,
                   filterKey: this.localStorageService.getDashboardFilterKey(),
                  }
    return data;
  }

  buildRequestBodyForChartDataInside(dbObjId: any):any{
    const data = { dbObjId : dbObjId,
      filterKey: this.localStorageService.getDashboardFilterKey()}
    return data;
  }

  buildRequestBodyForChartInsideData(rb: any):any{
    const data = { dbObjId : rb.child_obj_id,
      filterKey: this.localStorageService.getDashboardFilterKey(),
      __param: rb.__param,
      __param2: rb.__param2,
      parentTitle: rb.responseData.title,
      parentValue: rb.responseData.value
     }
    return data;
  }

  buildOssPidRequestForChronologyData(): OssPidRequest {
    const ossPidRequest: OssPidRequest = this.buildOssPidRequest();
    // let paramData: string[] = codeStatus.paramData.split(',');
    ossPidRequest.requestData.position = 5001;
    ossPidRequest.requestData.dashboardId = this.localStorageService.getCurrentDashboard().id;
    ossPidRequest.requestData.filter_key = this.localStorageService.getDashboardFilterKey();
    return ossPidRequest;
  }

  butildRequstDataForInsideDetail(parentChartData: ParentChartData): any {
    const reqBody = {
      osspidRequest: {
        requestType: REQUEST_TYPE,
        deviceId: this.utilService.getDeviceId(),
        clientId: this.utilService.getClientId(),
        secretKey: this.utilService.getSecretKey(),
        version: VERSION,
        requestData: {
          oauth_token: this.localStorageService.getOauthToken(),
          oauth_data: this.localStorageService.getOauthData(),
          ipAddress: this.utilService.getIpAddress(),
          userAgent: this.utilService.getBrowserUserAgent(),
          token: '',
          position: '503',
          filter_key: this.localStorageService.getDashboardFilterKey(),
          parent_obj_id: parentChartData.parent_obj_id,
          child_obj_id: parentChartData.child_obj_id,
          __param: parentChartData.__param,
          __param2: parentChartData.__param2
        }
      }
    };
    return reqBody;
  }

  buildCommonRequestBody(): any {
    // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
    const reqBody = {
      osspidRequest: {
        requestType: REQUEST_TYPE,
        deviceId: this.utilService.getDeviceId(),
        clientId: this.utilService.getClientId(),
        secretKey: this.utilService.getSecretKey(),
        version: VERSION,
        requestData: {
          oauth_token: this.localStorageService.getOauthToken(),
          oauth_data: this.localStorageService.getOauthData(),
          ipAddress: this.utilService.getIpAddress(),
          userAgent: this.utilService.getBrowserUserAgent()
        }
      }
    };
    return reqBody;
  }

  buildRequestBodyForAssignDashboardByUserId(userId, dashboardId): any {
    // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
    const reqBody = {
      osspidRequest: {
        requestType: REQUEST_TYPE,
        deviceId: this.utilService.getDeviceId(),
        clientId: this.utilService.getClientId(),
        secretKey: this.utilService.getSecretKey(),
        version: VERSION,
        requestData: {
          oauth_token: this.localStorageService.getOauthToken(),
          oauth_data: this.localStorageService.getOauthData(),
          ipAddress: this.utilService.getIpAddress(),
          userAgent: this.utilService.getBrowserUserAgent(),
          user_id: userId,
          dashboardId
        }
      }
    };
    return reqBody;
  }

  buildRequestBodyForChangeAdminStatusByUserId(userId, admin): any {
    // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
    const reqBody = {
      osspidRequest: {
        requestType: REQUEST_TYPE,
        deviceId: this.utilService.getDeviceId(),
        clientId: this.utilService.getClientId(),
        secretKey: this.utilService.getSecretKey(),
        version: VERSION,
        requestData: {
          oauth_token: this.localStorageService.getOauthToken(),
          oauth_data: this.localStorageService.getOauthData(),
          ipAddress: this.utilService.getIpAddress(),
          userAgent: this.utilService.getBrowserUserAgent(),
          user_id: userId,
          admin
        }
      }
    };
    return reqBody;
  }

  buildRequestBodyForChangeUserStatusByUserId(userId, userStatus): any {
    // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
    const reqBody = {
      osspidRequest: {
        requestType: REQUEST_TYPE,
        deviceId: this.utilService.getDeviceId(),
        clientId: this.utilService.getClientId(),
        secretKey: this.utilService.getSecretKey(),
        version: VERSION,
        requestData: {
          oauth_token: this.localStorageService.getOauthToken(),
          oauth_data: this.localStorageService.getOauthData(),
          ipAddress: this.utilService.getIpAddress(),
          userAgent: this.utilService.getBrowserUserAgent(),
          user_id: userId,
          user_status: userStatus
        }
      }
    };
    return reqBody;
  }

  buildRequestBodyForEnableDisableDeviceByUserId(userId): any {
    // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
    const reqBody = {
      osspidRequest: {
        requestType: REQUEST_TYPE,
        deviceId: this.utilService.getDeviceId(),
        clientId: this.utilService.getClientId(),
        secretKey: this.utilService.getSecretKey(),
        version: VERSION,
        requestData: {
          oauth_token: this.localStorageService.getOauthToken(),
          oauth_data: this.localStorageService.getOauthData(),
          ipAddress: this.utilService.getIpAddress(),
          userAgent: this.utilService.getBrowserUserAgent(),
          user_id: userId
        }
      }
    };
    return reqBody;
  }

  buildRequestBodyForAssignDashboardObject(dashboardObjId, dashboardId): any {
    // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
    const reqBody = {
      osspidRequest: {
        requestType: REQUEST_TYPE,
        deviceId: this.utilService.getDeviceId(),
        clientId: this.utilService.getClientId(),
        secretKey: this.utilService.getSecretKey(),
        version: VERSION,
        requestData: {
          oauth_token: this.localStorageService.getOauthToken(),
          oauth_data: this.localStorageService.getOauthData(),
          ipAddress: this.utilService.getIpAddress(),
          userAgent: this.utilService.getBrowserUserAgent(),
          dashboard_object_id: dashboardObjId,
          dashboardId
        }
      }
    };
    return reqBody;
  }

  buildRequestBodyForAssignUserType(userTypeId, dashboardId): any {
    // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
    const reqBody = {
      osspidRequest: {
        requestType: REQUEST_TYPE,
        deviceId: this.utilService.getDeviceId(),
        clientId: this.utilService.getClientId(),
        secretKey: this.utilService.getSecretKey(),
        version: VERSION,
        requestData: {
          oauth_token: this.localStorageService.getOauthToken(),
          oauth_data: this.localStorageService.getOauthData(),
          ipAddress: this.utilService.getIpAddress(),
          userAgent: this.utilService.getBrowserUserAgent(),
          user_type: userTypeId,
          dashboardId
        }
      }
    };
    return reqBody;
  }


  buildRequestForDeviceDisplay(tvDashboard): any {
    const reqBody = {
      osspidRequest: {
        requestType: 'APP_DASHBOARD',
        deviceId: this.utilService.getDeviceId(),
        clientId: this.utilService.getClientId(),
        secretKey: this.utilService.getSecretKey(),
        version: VERSION,
        requestData: {
          ipAddress: this.utilService.getIpAddress(),
          userAgent: this.utilService.getBrowserUserAgent(),
          token: '',
          id: tvDashboard.id,
          code1: tvDashboard.code1,
          code2: tvDashboard.code2,
        }
      }
    };
    return reqBody;
  }

  buildRequestBodyForCreatingDashboard(dashboardTableData: DashboardTableData) {
    // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
    const reqBody = {
      osspidRequest: {
        requestType: REQUEST_TYPE,
        deviceId: this.utilService.getDeviceId(),
        clientId: this.utilService.getClientId(),
        secretKey: this.utilService.getSecretKey(),
        version: VERSION,
        requestData: {
          oauth_token: this.localStorageService.getOauthToken(),
          oauth_data: this.localStorageService.getOauthData(),
          ipAddress: this.utilService.getIpAddress(),
          userAgent: this.utilService.getBrowserUserAgent(),
          dashboard: {
              caption: dashboardTableData.caption,
              desktop: dashboardTableData.desktop,
              mobile: dashboardTableData.mobile,
              objId: dashboardTableData.objId,
              status: dashboardTableData.status,
              timeline: dashboardTableData.timeline,
              totalDurationTv: dashboardTableData.totalDurationTv,
              tv: dashboardTableData.tv
          }
        }
      }
    };
    return reqBody;
  }

  buildRequestBodyForCreatingUserType(userTypeListData: UserTypeListData) {
    // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
    const reqBody = {
      osspidRequest: {
        requestType: REQUEST_TYPE,
        deviceId: this.utilService.getDeviceId(),
        clientId: this.utilService.getClientId(),
        secretKey: this.utilService.getSecretKey(),
        version: VERSION,
        requestData: {
          oauth_token: this.localStorageService.getOauthToken(),
          oauth_data: this.localStorageService.getOauthData(),
          ipAddress: this.utilService.getIpAddress(),
          userAgent: this.utilService.getBrowserUserAgent(),
          user_type_obj: {
              id: userTypeListData.id,
              typeName: userTypeListData.typeName,
              status: userTypeListData.status
            },
        }
      }
    };
    return reqBody;
  }




  buildRequestBodyForUpdatingDashboard(dashboardTableData: DashboardTableData) {
    // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
    const reqBody = {
      osspidRequest: {
        requestType: REQUEST_TYPE,
        deviceId: this.utilService.getDeviceId(),
        clientId: this.utilService.getClientId(),
        secretKey: this.utilService.getSecretKey(),
        version: VERSION,
        requestData: {
          oauth_token: this.localStorageService.getOauthToken(),
          oauth_data: this.localStorageService.getOauthData(),
          ipAddress: this.utilService.getIpAddress(),
          userAgent: this.utilService.getBrowserUserAgent(),
          dashboard: {
            id: dashboardTableData.id,
            caption: dashboardTableData.caption,
            desktop: dashboardTableData.desktop,
            mobile: dashboardTableData.mobile,
            objId: dashboardTableData.objId,
            status: dashboardTableData.status,
            timeline: dashboardTableData.timeline,
            totalDurationTv: dashboardTableData.totalDurationTv,
            tv: dashboardTableData.tv
          }
        }
      }
    };
    return reqBody;
  }


  buildRequestBodyForUpdatingUserType(userTypeListData: UserTypeListData) {
    // used for profileInfo and isMobileLoggedIn and getQRCode and adminDashboardObjList
    const reqBody = {
      osspidRequest: {
        requestType: REQUEST_TYPE,
        deviceId: this.utilService.getDeviceId(),
        clientId: this.utilService.getClientId(),
        secretKey: this.utilService.getSecretKey(),
        version: VERSION,
        requestData: {
          oauth_token: this.localStorageService.getOauthToken(),
          oauth_data: this.localStorageService.getOauthData(),
          ipAddress: this.utilService.getIpAddress(),
          userAgent: this.utilService.getBrowserUserAgent(),
          user_type_obj: {
            id: userTypeListData.id,
            typeName: userTypeListData.typeName,
            status: userTypeListData.status
          },
        }
      }
    };
    return reqBody;
  }
}
