/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./suggestion-snackbar.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./suggestion-snackbar.coponent";
import * as i3 from "@angular/material/snack-bar";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../utils/util.service";
var styles_SuggestionSnackbarComponent = [i0.styles];
var RenderType_SuggestionSnackbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SuggestionSnackbarComponent, data: {} });
export { RenderType_SuggestionSnackbarComponent as RenderType_SuggestionSnackbarComponent };
export function View_SuggestionSnackbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "row flex-center-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col-5"], ["style", "padding: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["style", "color: white;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Would you like to view this in the app"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "col-3"], ["style", "padding: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", "Get it on Google Play"], ["src", "assets/img/brand/small_play_store.png"], ["style", "margin-left: auto; margin-right: auto; display: block;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.util.openAndroidAppDownloadPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "col-3"], ["style", "padding: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["alt", "Download on the App Store"], ["src", "assets/img/brand/small_ios_store.png"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.util.openIosAppDownloadPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "col-1"], ["style", "padding: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sbRef.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null))], null, null); }
export function View_SuggestionSnackbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-basic-snackbar", [], null, null, null, View_SuggestionSnackbarComponent_0, RenderType_SuggestionSnackbarComponent)), i1.ɵdid(1, 114688, null, 0, i2.SuggestionSnackbarComponent, [i3.MatSnackBarRef, i3.MAT_SNACK_BAR_DATA, i4.MatDialog, i5.UtilService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SuggestionSnackbarComponentNgFactory = i1.ɵccf("app-basic-snackbar", i2.SuggestionSnackbarComponent, View_SuggestionSnackbarComponent_Host_0, {}, {}, []);
export { SuggestionSnackbarComponentNgFactory as SuggestionSnackbarComponentNgFactory };
