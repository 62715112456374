import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiEndpoints } from '../../api-endpoints';
import { Router } from '@angular/router';
import { UtilService } from '../../utils/util.service';
import { OssService } from '../../utils/oss.service';
import { OssHelperService } from '../../utils/oss-helper.service';
import { map } from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiEndpoints = new ApiEndpoints();

  constructor(private storageService: StorageService,
    private httpClient: HttpClient,
    private router: Router,
    private ossHelperService: OssHelperService,
    private ossService: OssService,
    private utilService: UtilService) {
  }

  isLoginIn() {
    return this.storageService.read('oauth_token');
  }

  saveUserCredentials(oauthData: string, oauthToken: string) {
    this.storageService.save('oauth_data', oauthData);
    this.storageService.save('oauth_token', oauthToken);
  }

  logout() {
    const ossPidRequest = this.ossHelperService.buildOssPidRequestLogout();
    // const obj = this.utilService.buildOssPidRequestLogout(ossPidRequest);
    this.ossService.ossLogout(ossPidRequest).subscribe(
      response => {
        if (response.data.status === true) {
          this.storageService.clear();
          this.router.navigate(['/login']);
        }
      },
      error => console.log(error)
    );
  }

  loginWithOssId(): Observable<any> {
    const observable = new Subject<any>();
    this.httpClient.get(this.apiEndpoints.ossLoginUrl).subscribe(
      response => observable.next(response),
      error => observable.next(error)
    );
    return observable;
  }

  executeQrCodeForWebLoginReq(): Observable<any> {
    return this.httpClient.get(this.apiEndpoints.qrCodeForWebLoginUrl);
  }

  executeWebLoginStatusCheckReq(qrCode) {
    // console.log(`${this.apiEndpoints.qrCodeForWebLoginUrl}/${qrCode}`);
    return this.httpClient.get(`${this.apiEndpoints.qrCodeInfo}/${qrCode}`);
  }

  // qProLogin(userName: any, password: any): Observable<any> {
  //   const data = { username: userName, password: password };
  //   return this.httpClient.post(this.apiEndpoints.ossThirdPartyLoginUrl, data).pipe(
  //     map(value => value)
  //   );
  // }
}
