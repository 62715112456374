import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from '../login/auth.service';
import { Observable, pipe } from 'rxjs';
import { StorageService } from '../login/storage.service';
import { LocalStorageService } from '../shared/service/local-storage-service';
import { map, tap, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiEndpoints } from 'src/app/api-endpoints';
import { ApiBankHelperService } from 'src/app/modules/admin-module/service/api-bank-helper.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private apiEndpoints: ApiEndpoints = new ApiEndpoints();
  constructor(private auth: AuthService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private http: HttpClient,
    private apiBankHelperService: ApiBankHelperService,
    private _snackBar: MatSnackBar
  ) {
  }

  private tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if(req.url.includes('insight_resource')) {
      return next.handle(req);
    }

    if (req.url.endsWith('get_oss_pid_url') || req.url.endsWith('get-qr-code-for-web-login') || req.url.includes('qr-code-info') || req.url.includes('email') || req.url.includes('send-otp') || req.url.includes('email-login')) {
      return next.handle(req);
    }
    if (req.url.includes('api-bank/')) {

      if(this.localStorageService.getJWTToken() && this.tokenExpired(this.localStorageService.getJWTToken())){
        return this.handleApiBankRequest(next, req);
      }else if(!this.localStorageService.getJWTToken()){
        return this.handleApiBankRequest(next, req);
      }
      else{
        let token = this.localStorageService.getJWTToken();
        let authReq: HttpRequest<any> = null;
        authReq = req.clone({ setHeaders: this.getAPIBankHeaders(token) });
        return next.handle(authReq);
      }
    }
    else {
      let authReq: HttpRequest<any> = null;
      authReq = req.clone({ setHeaders: this.getHeaders() });
      return next.handle(authReq)
        .pipe(
          tap(data => {
            // console.log('data -----------', data);
            if (data.type === HttpEventType.Response) {
              if (data.status === 204) {
                // this._snackBar.open("You are logged in another browser", "", {
                //   duration: 2000
                // });
                this.router.navigate(['/login']);
              }
            }
          })
        );
    }
  }

  handleApiBankRequest(next, request): Observable<HttpEvent<any>> {
    return this.apiBankHelperService.getJWtToken()
      .pipe(switchMap(response => {
        this.localStorageService.setJWTToken(response['data']['accessToken']);
        request = request.clone({ setHeaders: this.getAPIBankHeaders(response['data']['accessToken']) });
        return next.handle(request) as Observable<HttpEvent<any>>;
      }));
  }

  getFeedbackHeaders(token): any {
    return {
      'content-type': 'application/json',
      'access-token': 'kBCfCXai5ttg2skpSM7rIU6ioCEDH0NS',
      'Access-Control-Allow-Origin': '*'
    };
    // 'Content-Type': 'application/json',
    // 'access-token': 'kBCfCXai5ttg2skpSM7rIU6ioCEDH0NS',
    // 'Access-Control-Allow-Origin': '*'
  }

  getAPIBankHeaders(token:any) {
    console.log('---',token);
    return {
      'authorization': 'Bearer ' + token,
    };
  }

  getHeaders(): any {
    return {
      'content-type': 'application/json',
      'oauth-token': this.localStorageService.getOauthToken(),
      'oauth-data': this.localStorageService.getOauthData(),
      'request-type': 'APP_DASHBOARD',
      'device-id': '31241234235345',
      'client-id': '159617a323cdb0dfd8025814c2676f202c1e513b',
      'secret-key': 'e07082e4faae7d8e6bbe4cbb5680b6adf4460539',
      'cache-control': 'no-cache',
      'Access-Control-Allow-Origin': '*',
      'platform': 'web',
      'device-info': this.getOsName()
    };
  }

  getOsName = () => {

    var platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      os = '';
    // console.log('Platform: ', platform);

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }
    return os;
  }
}
