<!--<div class="app-body">-->
<main class="main d-flex align-items-center">
    <div class="container" style="padding-top: 15%">
        <div class="row">
            <div class="col-md-5 mx-auto">
                <!--<div class="card-group">-->
                <div class="card p-4">
                    <div class="card-body text-center">
                        <!--<h1>Insight</h1>-->
                        <h5 class="text-danger">Inactive user. Please contact system admin for activation</h5>
                        <div class="row">
                            <div class="col-12">
                                <button type="button" (click)="logout()" class="btn btn-warning mx-auto">Logout</button>
                            </div>
                            <div class="col-4">
                                <!--<p style="float: right">Managed by </p>-->

                            </div>
                        </div>
                    </div>
                </div>
                <!--</div>-->
            </div>
        </div>
    </div>
</main>
<!--</div>-->