import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs/index';

@Injectable({providedIn: 'root'})
export class DashboardObjRefreshDataService {

  private dataSource = new BehaviorSubject<any>(null);
  data = this.dataSource.asObservable();

  constructor() { }

  updatedData(data: any) {
    this.dataSource.next(data);
  }

}
