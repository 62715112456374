import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs/index';
import {ApiEndpoints} from '../../../api-endpoints';
import {OssPidRequest} from "../../../model/oss-pid-request";
import {DashboardObject} from '../../../model/dashboard-object';
import {LocalStorageService} from '../../../core/shared/service/local-storage-service';
import {DashboardObjRefreshDataService} from '../../../core/shared/service/data-service';
import { UserWiseDashboardView } from 'src/app/model/user-wise-dashboard-view';
import {map} from 'rxjs/internal/operators';


const accessLogListUrl = 'access-log/get-list-by-user-id';
const dashboardGetListForUserByIdUrl = 'dashboard/get-list-for-user-by-id';
const accessLogLogoutUrl = 'access-log/logout';



@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  apiEndPoint = new ApiEndpoints();
  

  constructor(private httpClient: HttpClient,
              private localStorageService: LocalStorageService,
              private dataService: DashboardObjRefreshDataService) {
  }

  getDataForPostReq(fullUrl: string, ossPidRequest: Object): Observable<any> {
    return this.httpClient.post(fullUrl, ossPidRequest);
  }

  getDataForDashboard(dashboardId: string, filterKey: string): Observable<any> {
    let url = this.apiEndPoint.ROOT_URL + this.apiEndPoint.dashboardServiceUrl + 'dashboard/get-data';
    let data = { id: dashboardId, filterKey:filterKey};
    return this.httpClient.post(url, data, {observe: 'response'});
    //  --> to get response header
  }

  /*
  checkDatabaseConnection(dataSource: DataSource){
    let fullUrl = this.apiEndpoints.ROOT_URL + this.apiEndpoints.utilityServiceUrl + checkDbConnectionUrl;
    return this.http.post<DbConnectionInfo>(fullUrl, dataSource);

    getUserTableData(page: number, size: number): Observable<any> {
    let url = this.apiEndpoints.ROOT_URL + this.apiEndpoints.authServiceUrl + userGetListUrl;
    // let url = 'http://192.168.152.82:8082/'+userGetListUrl;
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.http.post(fullUrl, {}).pipe(
      map(value => value)
    );
  }
  }*/
  saveMaximizeData(userWiserDashboardView: UserWiseDashboardView): Observable<any>{
    let fullUrl = this.apiEndPoint.ROOT_URL + this.apiEndPoint.authServiceUrl + 'uwdv/create';
    return this.httpClient.post(fullUrl, userWiserDashboardView).pipe(map(value => value));
  }

  getConnectivityData(): Observable<any> {
    const observable = new Subject();
    this.httpClient.get(this.apiEndPoint.connectivityUrl).subscribe(
      response => {
        observable.next(response);
      }, error => {
        observable.next(error);
      }
    );
    return observable;
  }

  getStaticData(fullUrl: string): Observable<any> {
    return this.httpClient.get(fullUrl);
  }

  saveDashboardObjAndRefreshDashboardData(dashboards: DashboardObject[]) {
    let dashboardObjList = new Array<DashboardObject>();
    dashboardObjList = dashboards;
    this.localStorageService.saveDashboardObjs(dashboardObjList);
    this.dataService.updatedData(dashboardObjList);
  }

  getAccessLogList(userId: any,page:any,size:any) {
    let data = {id: userId};
    let url = this.apiEndPoint.ROOT_URL + this.apiEndPoint.authServiceUrl + accessLogListUrl;
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.httpClient.post(fullUrl, data).pipe(
      map(value => value)
    );
  }


  
  getDashboardObjAccessList(userId: any,page:any,size:any) {
    let data = {id: userId};
    let url = this.apiEndPoint.ROOT_URL + this.apiEndPoint.authServiceUrl + 'dashboard-obj/dashboard-obj-access-info';
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.httpClient.post(fullUrl, data).pipe(
      map(value => value)
    );
  }


  // getDashboardObjAccessList(userId: any,page:any,size:any) {
  //   const fullUrl1 = this.apiEndPoint.profileInfoUrl;
  //   const reqBody = {};
  //   this.httpClient.post(fullUrl1, reqBody).toPromise().then(res => {
  //     console.log('First Promise resolved ----- ', res);
  //     if (res['data']['content']['id']) {
  //       let data = {id: userId};
  //       let url = this.apiEndPoint.ROOT_URL + this.apiEndPoint.authServiceUrl + 'dashboard-obj/dashboard-obj-access-info';
  //       const fullUrl = `${url}?page=${page}&size=${size}`;

  //       return this.httpClient.post(fullUrl, data).pipe(
  //         map(value => value)
  //       );

  //       // this.httpClient.post(fullUrl, data).toPromise().then(data => {
  //       //   console.log('Second Promise resolved ----', data)
  //       // });
  //     }
  //   });
  // }



  getDashboardDataForUserById(page: number, size: number, profile:any): Observable<any> {
    let url = this.apiEndPoint.ROOT_URL + this.apiEndPoint.authServiceUrl + dashboardGetListForUserByIdUrl;
    const fullUrl = `${url}?page=${page}&size=${size}`;
    return this.httpClient.post(fullUrl, profile).pipe(
      map(value => value)
    );
  }

  getAccessLogLogoutData(data: any): Observable<any>{
    let fullUrl = this.apiEndPoint.ROOT_URL + this.apiEndPoint.authServiceUrl + accessLogLogoutUrl;
    return this.httpClient.post(fullUrl, data).pipe(
      map(value => value)
    );
  }
}
